import react, {useEffect, useRef, useState} from 'react';
import axios from "axios";

import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';

import BoardResolution from './BoardResolution';
import PersonBasicDetails from '../../SharedComponents/PersonBasicDetails';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';
import PanVerifiedCard from '../../SharedComponents/PanVerifiedCard';
import AadharVerifiedCard from '../../SharedComponents/AadharVerified';
import { upload } from '@testing-library/user-event/dist/upload';

export default function AuthorizedSignatoryKYC({onNext, setStepValid}) {
    const formKyc = useRef(null);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [maritalStatusSingle, setMaritalStatusSingle] = useState(false);
    const [maritalStatusMarried, setMaritalStatusMarried] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isBrEdit, setIsBrEdit] = useState(false);
    
    const [isNext, setIsNext] = useState(false);
    // var apiUrl = 'http://localhost/tradebee/api';
    var apiUrl = 'http://tb.binarycoders.in/api';


    const [basicDetails, setBasicDetails] = useState([]);
    const [kycDetails, setKycDetails] = useState([]);
    const [boardResolutionDetails, setBoardResolutionDetails] = useState([]);
    const [panVerified,  setPanVerified] = useState(false);
    const [aadhardVerified,  setAadharVerified] = useState(false);

    const [formData, setFormData] = useState({
        kyctype: "",
        identifierno:""
      })

    const storeAuthorizedKycApi = apiUrl +'/managementKyc/authorizedSignatoryKyc';
    const getBasicDetailsApi = apiUrl +'/managementKyc/authorizedSignatoryBasicDetails/user/';
    const getKycDetailsApi = apiUrl +'/managementKyc/authorizedSignatoryKyc/user/';
    const brStoreAPI = apiUrl +'/managementKyc/boardResolutions';
    const getBrDetailsApi = apiUrl +'/managementKyc/boardResolutions/details/';


    const handleFormInputChange = ({ target }) => {
        const { name, value } = target;
        // console.log("valueeeeeeeeeeeee",name,value);
        const newFormData = Object.assign({}, formData, { [name]: value });
        console.log("newFormmmmmmmmmmmmmmm",newFormData);
        setFormData(newFormData);
        
        console.log(isValid);
      };

     // Callback to handle file selection in DocumentUploadCard
     const handleFilesSelected = (files) => {
        setUploadedFiles(files);
        if (files.length > 0) {
            setIsValid(true);
            console.log(isValid);
        }
    };

    
    const handleCheckKYCType = () => {
        if (formData.kyctype === "pan" && formData.identifierno.length == 10) {
            const data = {
                pan_card: 1,
                pan_card_no: formData.identifierno
            };
            handleKycSubmit(data);
            setPanVerified(true);
            console.log('pan');
        }
        if (formData.kyctype == "aadhar" && formData.identifierno.length == 10) {
            const data = {
                aadhar_card: 1,
                aadhar_card_no: formData.identifierno
            };
            handleKycSubmit(data);
            setAadharVerified(true);
            console.log('aadhar');
        }
        
    };

    const handleMaritalStatus = () => {
        console.log(formData.maritalstatus);
          if (formData.maritalstatus === "single") {
              console.log(formData.maritalstatus);
              setMaritalStatusSingle(true);
              setMaritalStatusMarried(false);
          } else {
              console.log('married')
              setMaritalStatusSingle(false);
              setMaritalStatusMarried(true);  
          }
    }


    const handleKycSubmit = async (data) => {
        // Add the user ID to the data object
        let userId = localStorage.getItem('user_id');
        data['uid'] = parseInt(userId);
    
        // Define custom headers
        const headers = {
            'Content-Type': 'application/json',
        };
    
        axios.post(storeAuthorizedKycApi, data, { headers })
            .then(response => {
                if (response.status === 201) {
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Data added Successfully!',
                    }).then((result) => {

                        if (result.isConfirmed) {
                          setFormData({identifierno: '', kyctype: ''});
                        formKyc.current.reset();
                        }
                    });
                }
            })
            .catch(error => {
                console.error('There was an error storing the data:', error);
                // Handle error, e.g., show an error message
            });
      };

      const handleKycData = async (data) => {
        if (data.pan_card == 1) {
            setPanVerified(true);
        }

        if (data.aadhar_card == 1) {
            setAadharVerified(true);
        }
      }

      const handleFormSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData();
        let userId = localStorage.getItem('user_id');
        let companyId = localStorage.getItem('company_id');
        data.append('uid', userId);
        data.append('cid', companyId);

        // Add selected files to FormData
        uploadedFiles.forEach((file, index) => {
            data.append(`files[${index}]`, file);
        });
        
        try {
            const response = await axios.post(brStoreAPI, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log('br details', response.data);
            console.log('submitted', response.data);
            if (response.status === 201 && response.data.length != 0) {
                setStepValid(true);
                setBoardResolutionDetails(response.data);  // Store the uploaded files
                Swal.fire({
                    icon: 'success',
                    title: 'Data added Successfully!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUploadedFiles([]);  // Reset file state
                        formKyc.current.reset();  // Reset form
                        setStepValid(true);   
                        onNext();
                    }
                });
            }
        } catch (error) {
            console.error('There was an error storing the data:', error);
        }
    };




    const getBasicDetails = async () => {
        let userId = localStorage.getItem('user_id');
        axios.get(getBasicDetailsApi + userId)
        .then(response => {
            if (response.status === 200) {
                const data = response.data;
                setBasicDetails(data['data']);
                setIsLoading(false);
                
            }
        });
    };
    
    const getBoardResolutionDetails = async () => {
        let companyId = localStorage.getItem('company_id');
        axios.get(getBrDetailsApi + companyId)
        .then(response => {
            
            if (response.status === 200 && response.data.data.length != 0) {
                const data = response.data.data;
                setBoardResolutionDetails(data[0]);
                setIsLoading(false);
                setStepValid(true);
                
            }
        });

    };

    const getKycDetailsByUserId = async () => {
        let userId = localStorage.getItem('user_id');
        axios.get(getKycDetailsApi + userId)
        .then(response => {
            if (response.status === 200) {
                const data = response.data;
                setKycDetails(data['data']);
                handleKycData(data['data']);
                setIsLoading(false);
                
            }
        });
    };

      useEffect(() => {
        getBasicDetails();
        handleMaritalStatus();
        getBoardResolutionDetails();
        handleCheckKYCType();
        setIsValid(Object.values(formData).every(value => value != ""));
        
      }, [formData, isLoading]);
      
      useEffect(() => {
        
      }, [boardResolutionDetails, isBrEdit, isValid]);

      if (isLoading) {
        return <h3>Loading...</h3>;
      }
    return (
        <>
            <div className="documents-section">
                <div className="d-flex">
                    <h5 className="ms-auto"><i className="fas fa-plus text-primary"></i> Add New KYC</h5>
                </div>
                <div className="d-flex">
                    <h3>Authorized Signatory KYC</h3>
                    </div>
                    <div className="d-flex">
                    <ul className="nav nav-tabs">
                        <li className="nav-item d-flex align-items-center">
                            <a className="nav-link active" data-bs-toggle="tab" href="#Pan">PAN Verification</a>
                            {panVerified ? (<i className="fa-regular fa-circle-check"></i>) : <i className="far fa-clock"></i>}
                        </li>
                        <li className="nav-item d-flex align-items-center">
                            <a className="nav-link" data-bs-toggle="tab" href="#Aadhar">Aadhar Verfication</a>
                            {aadhardVerified ? (<i className="fa-regular fa-circle-check"></i>) : <i className="far fa-clock"></i>}
                        </li>
                    </ul>
                </div>
                <form ref={formKyc}>
                    <div className="col-md-12 tab-content">
                        <div id="Pan" className="tab-pane active">
                            <div className="d-flex dashboard-form-row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                                        <select className="form-select form-control" name="kyctype" onChange={handleFormInputChange}>
                                            <option value="">KYC Type</option>
                                            {panVerified !== true && <option value="pan">PAN</option>}
                                            {aadhardVerified !== true && <option value="aadhar">Aadhar Card</option>}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            placeholder="Identifier Number" name="identifierno" onChange={handleFormInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex dashboard-form-row verification-list">
                            {panVerified && <PanVerifiedCard />}
                            {aadhardVerified && <AadharVerifiedCard />}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 tab-content">
                        <div id="Aadhar" className="tab-pane">
                            <div className="d-flex dashboard-form-row verification-list">
                                <AadharVerifiedCard setVerified={aadhardVerified}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {(boardResolutionDetails.length == 0) &&
            <>
                <div className="documents-section">
                    <h3 className="h3 mb-3">Documents Upload</h3>
                    <p>Upload Board Resolution for Appointment of Authorised Signatory</p>
                    <DocumentUploadCard onFilesSelected={handleFilesSelected}/>
                </div>
                <div className="col-md-12 form-add-button">

                <button type="button" className="btn btn-primary mt-3" disabled={!isValid} onClick={handleFormSubmit}>Add</button>
                </div>
            </>
             }
             { boardResolutionDetails.length != 0 &&
                <BoardResolution data={boardResolutionDetails}/>
            }
            {basicDetails.length != 0 ? <div className="d-flex">
                    
                </div>
                 : ''}
                {basicDetails.length != 0 ? 
                <>
                <PersonBasicDetails basicDetails={basicDetails} title="Authorized Signatory Basic Details" />
                {/* <hr style={{border: '1px solid #ccc'}} className="mb-5 "/> */}
                </>
                 : ''}
        </>
    )
}