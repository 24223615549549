import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import editIcon from '../../../assets/images/icons/edit.svg';
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import buildingIcon from '../../../assets/images/icons/building.svg';
import moment from 'moment';
import '../../../App.css'

function BusinessAddress({ toast, onNext, setStepValid}) {
  const [businessAddressResult, setBusinessAddressResult] = useState([]);
  
  
  const [ownershipStatus, setOwnershipStatus] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isAddBtnClicked, setIsAddBtnClicked] = useState(false);
  const [isAddNewAddressForm, setIsAddNewAddressForm] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [tCompanyName, setTCompanyName] = useState('')

  const [isEditMode, setIsEditMode] = useState(false); // For Edit Mode
  const [editId, setEditId] = useState(null); // For tracking the edit item
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    area: "",
    selectedState: "",
    postalCode: "",
  });

  const storeBusinessAddress = "http://tb.binarycoders.in/api/businessAddress";
  const updateBusinessAddress = "http://tb.binarycoders.in/api/businessAddress/update";
  const deleteBusinessAddress = "https://api.binary-coders.in/businesskyc/deleteBusinessAddress";

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // useEffect(() => {
  //   console.log("toast : ",toast)
    
  //   toast(`Business Address Added Successfully!`)
  // }, [isValid, setStepValid]);

  useEffect(() => {
    setIsValid(Object.values(formData).every((value) => value !== ""));
  }, [formData]);

  const handleOwnershipChange = (event) => {
    setOwnershipStatus(event.target.value);
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    const uid = localStorage.getItem("user_id");
    const cid = localStorage.getItem("company_id");

    const apiUrl = isEditMode ? `${updateBusinessAddress}/${editId}` : storeBusinessAddress;
    const method = isEditMode ? 'put' : 'post';

    axios({
      method: method,
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        cid,
        uid,
        address: formData.address,
        city: formData.city,
        state: formData.selectedState,
        postal: formData.postalCode,
        area: formData.area,
        propery_ownership: ownershipStatus === "Owned" ? 1 : 0,
      },
    })
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: isEditMode ? 'Business Address Updated Successfully!' : 'Business Address Added Successfully!',
      }).then(() => {
        getBusinessAddress();
        resetForm();
        setIsAddBtnClicked(false);
        setIsEditMode(false);
        onNext();
      });
      // if(response?.status >= 200 && response?.status < 300){
      //   // toast(`${isEditMode ? 'Business Address Updated Successfully!' : 'Business Address Added Successfully!'}`)
      //   getBusinessAddress();
      //   resetForm();
      //   setIsAddBtnClicked(false);
      //   setIsEditMode(false);
      //   onNext();
      // }
    })

    .catch(error => {
      console.error("Error:", error.response);
    });
  };

  const getBusinessAddress = async () => {
    
    const cid = localStorage.getItem("company_id");

     await axios.get('http://tb.binarycoders.in/api/businessAddress/show', {
      params:{cid:cid}
    })  
    .then((res) => {
      if (res.status === 201) {
        setBusinessAddressResult(res.data.data);
        setIsAddBtnClicked(false);
        setStepValid(true);
        console.log('console business address', res.data);
        
      }
    })
    .catch((error) => {
      console.error("Error fetching company details:", error);
    });

    if(businessAddressResult.length > 0)
      console.log('Business address', businessAddressResult);
          
  };

  const handleEdit = (address) => {
    setFormData({
      address: address.address,
      city: address.city,
      selectedState: address.state,
      postalCode: address.postal,
      area: address.area,
    });
    setOwnershipStatus(address.propertyOwnership === 1 ? "Owned" : "Rented");
    setEditId(address.id);
    setIsEditMode(true);
    setIsAddBtnClicked(false);
  };

  const handleNewAddressForm = () => {
    console.log('Add new address form clicked');
    setIsAddNewAddressForm(true);
    setIsEditMode(false);
    resetForm();
  }

  const handleDelete = (id) => {
    axios.delete(`${deleteBusinessAddress}/${id}`)
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Business Address Deleted Successfully!',
        }).then(() => {
          getBusinessAddress();
        });
      })
      .catch(error => {
        console.error("Error deleting:", error);
      });
  };

  const resetForm = () => {
    setFormData({
      address: "",
      city: "",
      area: "",
      selectedState: "",
      postalCode: "",
    });
    setOwnershipStatus("");
    setEditId(null);
  };

  const handleCancelBtn = (e) => {
    e.preventDefault();
    setIsEditMode(false)
    setIsAddNewAddressForm(false)
    resetForm()
  }
  
  const getCompanyDetails = async () => {
    const uid = localStorage.getItem("user_id");
    
    
    if (!uid) {
      console.error("User ID is missing.");
      return;
    }
  
    axios.get('http://tb.binarycoders.in/api/business/show',{
      params: { uid: uid }
    })
    .then((res) => {
      console.log('First all effect', res.data.data);
      if (res.status === 201) {
        const companyName = localStorage.getItem('company_name');
        setTCompanyName(companyName);
        setCompanyDetails(res.data.data);
      }
      
    })
    .catch((error) => {
      console.error("Error fetching company details:", error);
    });
  };

  useEffect(() => {
      getCompanyDetails();
      getBusinessAddress();
  }, []);

  useEffect(() => {
    getBusinessAddress();
  }, [isAddBtnClicked, isEditMode]);

  return (
    <>
      <div>
        <div className="d-flex">
          <h3>Business Address</h3>
          <h5 className="ms-auto" onClick={handleNewAddressForm}><i className="fas fa-plus text-primary"></i> Add New Address</h5>
        </div>
        {(businessAddressResult.length == 0 || isEditMode || isAddNewAddressForm) && (
          <form>
            <div className="col-md-12">
              <div className="dashboard-form-row">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control w-100"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="d-flex dashboard-form-row three-columns">
                <div className="col-md-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <span className="form-control-icon state"><img src={arrowDownIcon}></img></span>
                    <select
                      className="form-select form-control"
                      name="selectedState"
                      value={formData.selectedState}
                      style={{color: formData.selectedState ? '#3276E8' : '#5A5A5A'}}
                      onChange={handleChange}
                    >
                      <option value="">State</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      placeholder="Postal Code"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex dashboard-form-row">
                <div className="col-md-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Area"
                      name="area"
                      value={formData.area}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><strong>Property Ownership</strong></label>
                    <div className="radio-group">
                      <label>
                        <input
                          type="checkbox"
                          name="ownership"
                          value="Owned"
                          onChange={handleOwnershipChange}
                          checked={ownershipStatus === "Owned"}
                        />
                        <span>Owned</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="ownership"
                          value="Rented"
                          onChange={handleOwnershipChange}
                          checked={ownershipStatus === "Rented"}
                        />
                        <span>Rented</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 form-add-button">
                <button
                  className="btn btn-secondary login-width"
                  onClick={handleAddButtonClick}
                  disabled={!isValid}>
                  {isEditMode ? 'Update' : 'Add'}
                </button>
                &nbsp;
                {isEditMode && (

                <button
                  className="btn login-width"
                  onClick={handleCancelBtn}
                  >
                  Cancel
                </button>
                )}
              </div>
            </div>
          </form>
        )}
        {(businessAddressResult.length > 0 && !isEditMode && !isAddNewAddressForm) && (
        <div className="modal-content company-detail-modal">
          <h2>Business Address:</h2>
          {businessAddressResult.length != 0 && (
            <div>
              {businessAddressResult.map((address, index) => (
                <div 
                  className="col-md-12 d-flex address-entry" 
                  key={index} 
                  style={{
                    // border: "1px solid #ccc", // Add a border
                    // padding: "15px",          // Add padding for a better look
                    marginBottom: "10px",     // Add margin to separate entries
                    borderRadius: "5px",      // Optional: to round the corners
                  }}
                >
                <div className="businessContainer">
                  <div className="businessAddressContainer">
                    <div className="businessWrapper">
                          <span>Address: {address?.address}</span>
                    </div>
                    <div className="businessWrapper">
                          <span>State: {address?.city}</span>
                    </div>
                    <div className="businessWrapper">
                          <span>Postal: {address?.postal}</span>
                    </div>
                  </div>
                  <div className="businessLocationContainer">
                     <span>State: {address.state}</span>
                     <span>Area: {address.area}</span>
                  </div>
                  <div className="iconContainer">
                    <span className="icons" onClick={() => handleDelete(address.id)}>
                      <img src={deleteIcon} alt="Delete" />
                    </span>
                  <span className="icons" onClick={() => handleEdit(address)}>
                      <img src={editIcon} alt="Edit" />
                    </span>
                  </div>
                </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        )}

      {companyDetails.length != 0 && (
          <div className="mt-5">
          <h3>Company Details</h3>

            <div className="modal-content company-detail-modal">
            <h2> 
              <span className="company-title"><img src={buildingIcon}/></span>  {tCompanyName}
            </h2>
              <div className="col-md-12 d-flex justify-content-start align-items-baseline">
                  <div className="col-md-11">
                    <table className="companyDetailsTableWrapper">
                      <tbody>
                        <tr>
                          <td>Date:</td>
                          <td>{moment(companyDetails?.incordate).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td>Company Type:</td>
                          <td>{companyDetails?.ctype}</td>
                        </tr>
                        <tr>
                          <td>Nature of Business:</td>
                          <td>{companyDetails?.busnature}</td>
                        </tr>
                        <tr>
                          <td>Industry Type:</td>
                          <td>{companyDetails?.indtype}</td>
                        </tr>
                        <tr>
                          <td>Collateral:</td>
                          <td>{companyDetails?.cltype}</td>
                        </tr>
                        <tr>
                          <td>Memorandum of Association (MOA):</td>
                          <td>{companyDetails?.moa ? 'Completed' : 'Pending'}</td>
                        </tr>
                        <tr>
                          <td>Articles of Association (AOA):</td>
                          <td>{companyDetails?.aoa ? 'Completed' : 'Pending'}</td>
                        </tr>
                        <tr>
                          <td>Certificate of Incorporation (CIN):</td>
                          <td>{companyDetails?.cin ? 'Completed' : 'Pending'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="companyIconContainer">
                    <span className="icons">
                        <img src={deleteIcon} alt="Delete" />
                      </span>
                    <span className="icons">
                        <img src={editIcon} alt="Edit" />
                      </span>
                  </div>
                
              </div>
            </div>
          </div>)}
      </div>
    </>
  );
}

export default BusinessAddress;