import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';

export default function BankStatementList({handleEdit, handleDelete, isHeading}) {
    const [uploadedData, setUploadedData] = useState([]);
    const getAPI = 'http://tb.binarycoders.in/api/bankStatement/show?cid=';
    const getBankStatements = () => {
        let companyId = localStorage.getItem('company_id');
        axios.get(getAPI + companyId)
            .then(response => {
                const data = response.data.data; // Assuming data is an array of bank statements
                setUploadedData(data); 
            })
            .catch(error => {
                console.error('Error fetching bank statements:', error);
            });
    };

    useEffect(() => {
        getBankStatements();
    }, []);
    return (
            <div className='mt-5'>
                {!isHeading && <h3>Upload Bank Statement</h3>}
                <ul className="bank-list balance-sheet">
                    {uploadedData.map((data) => (
                        <li key={data.id}>
                            <span className="icon"><i className="far fa-file-alt"></i></span>
                            <div className="bank-info">
                                <h4>Bank Statement ({data.name} - {data.accno})</h4>
                                
                            </div>
                            <div className='cash-text'>
                                <p><strong>{moment(data.from).format('DD-MMM-YYYY')} to {moment(data.to).format('DD-MMM-YYYY')}</strong></p>
                            </div>
                            <div className="edit-del-icon">
                                <i className="fas fa-eye"></i>
                                <i className="fas fa-edit" onClick={() => handleEdit(data.id)}></i>
                                <i className="fas fa-trash" onClick={() => handleDelete(data.id)}></i>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
    )
}