export default function BoardResolution({data}) {
    return (
        <div className="mt-4 mb-3">
            <h3>Board Resolution</h3>
            <div className="col-md-11">
                <div className="col-md-3 icons-wrap">
                    
                </div>
                <div className="documents-table mt-5">
                    <table>
                        <tbody>
                        <tr>
                            <th>Document File</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>{data.file_path}</td>
                            <td><span className="icons"><i className="fas fa-edit"></i></span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            )
        }