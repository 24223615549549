import React, { useEffect, useState } from 'react';
import axios from 'axios';


function BalanceSheetList({ handleEdit, handleDelete, isHeading, isAdded }) {

    const getAPI = 'http://tb.binarycoders.in/api/balsheet/show?cid=';
    const [uploadedData, setUploadedData] = useState([]);

    const getBalanceSheet = () => {
        let companyId = localStorage.getItem('company_id')
        axios.get(getAPI + companyId)
            .then(response => {
                const data = response.data.data; // Assuming data is an array of balance sheets
                setUploadedData(data); 
                // filterUploadedYears();
                
                
            })
            .catch(error => {
                console.error('Error fetching balance sheets:', error);
            });
    };
    useEffect(() => {
        getBalanceSheet();
    }, [isAdded]);
    
    return (
        <div className="mt-5">
            {!isHeading && <h3>Upload Balance Sheet</h3>}
            <ul className="bank-list balance-sheet">
                {uploadedData.map((data, index) => (
                    <li key={data.id}>
                        <span className="icon">
                            <i className="far fa-file-excel"></i>
                        </span>
                        <div className="bank-info">
                            <h4>Balance Sheet ({data.finyear})</h4>
                        </div>
                        <div className="cash-text">
                            <p>Turnover {data.bustrnover} Crores</p>
                        </div>
                        <div className="edit-del-icon">
                            <a href={data.findoc} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-eye"></i>
                            </a>
                            
                            <i className="fas fa-edit" onClick={() => handleEdit(data.id)}></i>
                            <i className="fas fa-trash" onClick={() => handleDelete(data.id)}></i>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BalanceSheetList;
