import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BankAccountList from './BankAccountList';

import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';


function BankAccount(onNext, setStepValid) {
    const [accId, setAccId] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [repeatAccountNumber, setRepeatAccountNumber] = useState("");
    const [accountType, setAccountType] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountPurpose, setAccountPurpose] = useState({
        repayment: false,
        disbursement: false,
        both: false
    });

    
    const [httpMethod, setHttpMethod] = useState('POST');
    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [isAddNewAccount, setIsAddNewAccount] = useState(false);

    var apiUrl = 'http://tb.binarycoders.in/api/businessAccount';

    const handleBankNameChange = (e) => {
        setBankName(e.target.value);
        handleValueCheck();
    };

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
        handleValueCheck();
    };

    const handleRepeatAccountNumberChange = (e) => {
        setRepeatAccountNumber(e.target.value);
        if(accountNumber !== e.target.value){
            setIsValid(false);
        }
    };

    const handleAccountTypeChange = (e) => {
        setAccountType(e.target.value);
        handleValueCheck();
    };

    const handleIfscCodeChange = (e) => {
        setIfscCode(e.target.value);
        handleValueCheck();
    };

    const handleAccountHolderNameChange = (e) => {
        setAccountHolderName(e.target.value);
        handleValueCheck();
    };

    const handleValueCheck = () => {
        if ((bankName === "" || accountNumber === "" || repeatAccountNumber === "" || accountType === "" || ifscCode === "" || accountHolderName === "") && (!accountPurpose.repayment || !accountPurpose.disbursement || !accountPurpose.both)) {
            setIsValid(false);
        } else {
            setIsValid(true);
            
        }
        console.log(isValid);
    }

    const handleAccountPurposeChange = (e) => {
        const { name, checked } = e.target;
        setAccountPurpose(prevState => ({
            ...prevState,
            [name]: checked,
            [name === 'disbursement' ? 'repayment' : 'disbursement'] : false
        }));
        handleValueCheck();
    };

    const handleAddNewAccountClicked = () => {
        console.log("Add new account clicked");
        setIsAddNewAccount(true);
    }


    const handleEdit = (account) => {
        console.log("Edit account clicked");
        console.log(account);
        setAccId(account.id);
        setBankName(account.bname);
        setAccountNumber(account.accno);
        setRepeatAccountNumber(account.accno);
        setAccountType(account.acctype == 1 ? 'Savings' : 'Current');
        setIfscCode(account.ifsccode);
        setAccountHolderName(account.accholdername);
        setAccountPurpose({
            repayment: account.accpurpose == 1 ? true : false,
            disbursement: account.accpurpose == 2 ? true : false,
            both: account.accpurpose == 3 ? true : false
        });
        setEditMode(true);
        setIsAddNewAccount(true);
        setHttpMethod('PUT');
    }

    const handleCancel = () => {
        getBankAccountList()
        setBankName("");
        setAccountNumber("");
        setRepeatAccountNumber("");
        setAccountType("");
        setIfscCode("");
        setAccountHolderName("");
        setAccountPurpose({
            repayment: false,
            disbursement: false,
            both: false
        });
        setEditMode(false);
        setIsAddNewAccount(false);
        setHttpMethod('POST');
    }

    const handleAddAccount = () => {
        let apiUrlLocal;
        // Prepare data to send
        const company_id=localStorage.getItem('company_id');
        const user_id=localStorage.getItem('user_id');
        console.log(accountPurpose);

        const data = {
            cid:company_id,
            uid:user_id,
            bname:bankName,
            adid:accountNumber,
            accno:accountNumber,
            // repeatAccountNumber,
            acctype:accountType == 'Savings' ? 1 : 2,
            ifsccode:ifscCode,
            accholdername:accountHolderName,
            accpurpose:1,
            accverified: 1
        };

        if(httpMethod == 'PUT') {
            data.id = accId;
        }

        if(httpMethod == 'PUT') {
            apiUrlLocal = apiUrl+'/'+accId;
        } else {
            apiUrlLocal = apiUrl;
        }
        // Send data to the backend API
        fetch(apiUrlLocal, {
            method: httpMethod,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                if(data.status == "error") {
                    setErrors(data.errors);
                    console.log(data.errors);
                    return;
                }

                    
                console.log('Success:', data);
                // Reset the form fields after successful submission
                setBankName("");
                setAccountNumber("");
                setRepeatAccountNumber("");
                setAccountType("");
                setIfscCode("");
                setAccountHolderName("");
                setAccountPurpose({
                    repayment: accountPurpose.repayment,
                    disbursement: accountPurpose.disbursement,
                    both: accountPurpose.both
                });
                handleCancel();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const getBankAccountList = () => {
        const company_id = localStorage.getItem('company_id');
        axios.get('http://tb.binarycoders.in/api/businessAccount/show', {
            params: {
                cid: company_id,
            }}).then((response) => {
            setAccountList(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
    
    }

    useEffect(() => {
        getBankAccountList();
    }, []);
    
    if (accountList.length > 0 && !isAddNewAccount) {
        return <BankAccountList newAccountBtn={handleAddNewAccountClicked} isEditMode={handleEdit}/>
    }

    return isAddNewAccount || accountList.length == 0 ? (
        <div>
            <h3>Bank Account</h3>
            <form>
                <div className="dashboard-form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Bank Name" value={bankName} onChange={handleBankNameChange} />
                            <span className="text-danger"><em>{errors?.bname ? 'Bank Name is Required' : ''}</em></span>
                        </div>
                    </div>
                </div>
                <div className="dashboard-form-row d-flex">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Account Number" value={accountNumber} onChange={handleAccountNumberChange} />
                            <span className="text-danger"><em>{errors?.accno ? 'Account No is Required' : ''}</em></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Repeat Account Number" value={repeatAccountNumber} onChange={handleRepeatAccountNumberChange} />
                            <span className="text-danger"><em>{errors?.accno ? 'Account No is Required' : ''}</em></span>
                        </div>
                    </div>
                </div>
                <div className="dashboard-form-row d-flex">
                    <div className="col-md-6">
                        <div className="form-group">
                        <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                            <select className="form-select form-control" value={accountType} onChange={handleAccountTypeChange}>
                                <option>Account Type</option>
                                <option>Savings</option>
                            </select>
                            <span className="text-danger"><em>{errors?.acctype ? 'Account Type is Required' : ''}</em></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="IFSC Code" value={ifscCode} onChange={handleIfscCodeChange} />
                            <span className="text-danger"><em>{errors?.ifsccode}</em></span>
                        </div>
                    </div>
                </div>
                <div className="dashboard-form-row d-flex">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Account Holder Name" value={accountHolderName} onChange={handleAccountHolderNameChange} />
                            <span className="text-danger"><em>{errors?.accholdername}</em></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label><strong>Account Purpose</strong></label>
                            <div className="radio-group">
                                <label><input type="checkbox" name="repayment" checked={accountPurpose.repayment || accountPurpose.both} onChange={handleAccountPurposeChange} /> <span>Repayment</span></label>
                                <label><input type="checkbox" name="disbursement" checked={accountPurpose.disbursement || accountPurpose.both} onChange={handleAccountPurposeChange} /> <span>Disbursement</span></label>
                                <label><input type="checkbox" name="both" checked={accountPurpose.both} onChange={handleAccountPurposeChange} /> <span>Both</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 form-add-button">
                <button type="button" className="btn btn-primary" disabled={!isValid} onClick={handleAddAccount}>{ editMode ? 'Update' : 'Add'}</button> &nbsp;
                <button type="button" className="btn btn-primary" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    ) : ('');
}

export default BankAccount;
