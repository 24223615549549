import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from '../../../assets/images/icons/calendar.svg';
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';

import AuthorizedBasicDetailsModal from "../../../modals/AuthorizedBasicDetailsModal";
import PersonBasicDetails from "../../SharedComponents/PersonBasicDetails";

export default function AuthorizedBasicDetails({onNext, setStepValid}) {
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditButtonClicked, isSetEditButtonClicked] = useState(false);
    const [isFormUpdated, isSetFormUpdated] = useState(false);


    const [basicDetails, setBasicDetails] = useState([]);
    const [isAddBtnClicked, isSetAddBtnClicked] = useState(false);    
    const [maritalstatusSingle, setMaritalStatusSingle] = useState(false);
    const [maritalstatusMarried, setMaritalStatusMarried] = useState(false);
    const [dob, setDob] = useState("");
    
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [isPhotoValid, setIsPhotoValid] = useState(false);
    const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSize = 4 * 1024 * 1024; // 4MB

    const [isNext, setIsNext] = useState(false);

    const datepickerRef = useRef(null);
    // var apiUrl = 'http://localhost/tradebee/api';
    var apiUrl = 'http://tb.binarycoders.in/api';
    

    const [formData, setFormData] = useState({
        gender: "",
        firstname: "",
        lastname:"",
        fatherspouse:"",
        relfirstname:"",
        rellastname:"",
        dob:"",
        mobileno:"",
        email:"",
        maritalstatus:"",
      });

      const storeAuthorizedBasicDetailsApi = apiUrl +'/managementKyc/authorizedSignatoryBasicDetails';
      const updatePhotoApi = apiUrl + '/managementKyc/authorizedSignatoryBasicDetails/photo/upload';
      const getBasicDetailsApi = apiUrl + '/managementKyc/authorizedSignatoryBasicDetails/user/';

      
      
    const handleFormChange = ({ target }) => {
        const { name, value } = target;
        // console.log("valueeeeeeeeeeeee",name,value);
        const newFormData = Object.assign({}, formData, { [name]: value });
        console.log("newFormmmmmmmmmmmmmmm",newFormData);
        setFormData(newFormData);
        
        console.log(isValid);
    };

    
    const handleSelectedDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setDob(formattedDate);
        setFormData({ ...formData, dob: formattedDate });
    };

    const handleDateIconClick = () => {
        datepickerRef.current.setOpen(true);
    }

      // Handle file selection
    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
        // Validate file size and type
        if (!allowedFileTypes.includes(file.type)) {
            Swal.fire("Invalid file type", "Please select a JPG, JPEG, or PNG file.", "error");
            return;
        }

        if (file.size > maxSize) {
            Swal.fire("File too large", "The file size must be less than 4MB.", "error");
            return;
        }

        // Set the selected file and create a preview
        setSelectedPhoto(file);
        setPhotoPreview(URL.createObjectURL(file));
        setIsPhotoValid(true);
    }
  };

    // Handle file upload
    const handleFileUpload = async (id) => {
        if (!selectedPhoto) return;

        const formData = new FormData();
        formData.append("photo", selectedPhoto);
        formData.append("uid", id);

        try {
        const response = await axios.post(updatePhotoApi, formData, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 201) {
            Swal.fire("Success", "File uploaded successfully!", "success");
        }
        } catch (error) {
        Swal.fire("Error", "There was an error uploading the file", "error");
        }
    };

      const handleFormSubmit = async (e) => {
        let userId = localStorage.getItem('user_id');
        e.preventDefault();

        const data = {
            gender: formData.gender,
            first_name: formData.firstname,
            last_name: formData.lastname,
            relationship: formData.fatherspouse,
            rel_first_name: formData.relfirstname,
            rel_last_name: formData.rellastname,
            mobile_no: parseInt(formData.mobileno),
            dob: formData.dob,
            email: formData.email,
            marital_status: formData.maritalstatus,
            uid: userId
        };
    
        // Define custom headers
        const headers = {
            'Content-Type': 'application/json',
        };
    
        axios.post(storeAuthorizedBasicDetailsApi, data, { headers })
            .then(response => {
                if (response.status === 201) {
                    setStepValid(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Data added Successfully!',
                    }).then((result) => {
                        handleFileUpload(userId);
                        
                        if (result.isConfirmed) {
                            isSetAddBtnClicked(true);
                            onNext();
                        }
                    });
                }
            })
            .catch(error => {
                console.error('There was an error storing the data:', error);
                // Handle error, e.g., show an error message
            });
      };

      const handleFormUpdate = async (e) => {
        e.preventDefault();
        let userId = localStorage.getItem('user_id');
        handleSelectedDate(formData.dob);
        
        const data = {
            gender: formData.gender,
            first_name: formData.firstname,
            last_name: formData.lastname,
            relationship: formData.fatherspouse,
            rel_first_name: formData.relfirstname,
            rel_last_name: formData.rellastname,
            mobile_no: parseInt(formData.mobileno),
            dob: formData.dob,
            email: formData.email,
            marital_status: formData.maritalstatus,
            uid: userId
        };
    
        // Define custom headers
        const headers = {
            'Content-Type': 'application/json',
        };
    
        axios.post(storeAuthorizedBasicDetailsApi, data, { headers })
            .then(response => {
                if (response.status === 201) {
                    setIsNext(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Data updated Successfully!',
                    }).then((result) => {
                        handleFileUpload(userId);
                        
                        if (result.isConfirmed) {
                            isSetAddBtnClicked(true);
                            isSetEditButtonClicked((prev) => !prev);
                        }
                    });
                }
            })
            .catch(error => {
                console.error('There was an error storing the data:', error);
                // Handle error, e.g., show an error message
            });
      };

      const getBasicDetails = async () => {
        let userId = localStorage.getItem('user_id');
        axios.get(getBasicDetailsApi + userId)
        .then(response => {
            if (response.status === 200) {
                const data = response.data;
                setBasicDetails(data['data']);
                setIsLoading(false);
                setStepValid(true);               
            }
        });
    };

    const handleEditButton = () => {
        
        setFormData(
            {
                gender: basicDetails.gender,
                firstname: basicDetails.first_name,
                lastname: basicDetails.last_name,
                fatherspouse: basicDetails.relationship,
                relfirstname: basicDetails.rel_first_name,
                rellastname: basicDetails.rel_last_name,
                dob: moment(basicDetails.dob).format('YYYY-MM-DD'),
                mobileno: basicDetails.mobile_no,
                email: basicDetails.email,
                maritalstatus: basicDetails.marital_status,
              }
        );
        setPhotoPreview(basicDetails.photo_url || null);
        isSetEditButtonClicked(true);
    }

      const handleMaritalStatus = () => {
          
            if (formData.maritalstatus === "single") {
                console.log(formData.maritalstatus);
                setMaritalStatusSingle(true);
                setMaritalStatusMarried(false);
            } else {
                console.log('married')
                setMaritalStatusSingle(false);
                setMaritalStatusMarried(true);  
            }
      }

      useEffect(() => {
        // Fetch basic details only on component mount
        getBasicDetails();
      }, [isAddBtnClicked]); // No dependencies here, it runs once on component mount
    
        useEffect(() => {
            // Handle marital status change separately
            if (formData.maritalstatus) {
                handleMaritalStatus();
            }
        }, [formData.maritalstatus]); // Runs only when maritalstatus changes
        
        useEffect(() => {
            // Validate form whenever formData changes
            setIsValid(Object.values(formData).every(value => value !== ""));
        }, [formData]);
    

        useEffect(() => {
            if (basicDetails.length != 0) {
              setStepValid(true);
            } else {
              setStepValid(false);
            }
          }, [isValid, setStepValid]);
    
      if(isLoading) return <h4 className='loaderHeading'>Loading...</h4>
    
      return (
        <div>
            <h3>Authorized Signatory Basic Details</h3>
            
            { basicDetails.length != 0 && isEditButtonClicked == false ? (<PersonBasicDetails title="" basicDetails={basicDetails} onEditClick={handleEditButton} />) : (
             <form>
                <div className="col-md-12">
                    <div className="d-flex dashboard-form-row">
                        <div className="form-group photo-upload">
                            <input type="file" className="form-control" placeholder="First Name" name="photo" onChange={handlePhotoChange}/>
                            <div className="passport-photo-sec border-primary">
                                <i className="fa fa-thin fa-upload"></i>
                                <h5>Passport size Photograph</h5>
                            </div>
                            {photoPreview && (
                                <div className="float-right" style={{position: 'absolute', left: '130px', top: '-50px'}}>
                                    <img src={photoPreview} alt="Preview" style={{ height: "150px", margin: "20px" }} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex dashboard-form-row three-columns">
                        <div className="col-md-4">
                            <div className="form-group">
                                <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                                <select className="form-select form-control" placeholder="Select Gender" value={formData.gender} name="gender" onChange={handleFormChange}>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="First Name" value={formData.firstname} name="firstname" onChange={handleFormChange}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Last Name" value={formData.lastname} name="lastname" onChange={handleFormChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex dashboard-form-row three-columns">
                        <div className="col-md-4">
                            <div className="form-group">
                                <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                                <select className="form-select form-control" value={formData.fatherspouse} name="fatherspouse" onChange={handleFormChange}>
                                    <option>Father / Spouse</option>
                                    <option>Father</option>
                                    <option>Spouse</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="First Name" value={formData.relfirstname} name="relfirstname" onChange={handleFormChange}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Last Name" value={formData.rellastname} name="rellastname" onChange={handleFormChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex dashboard-form-row three-columns">
                        <div className="col-md-4">
                        <div className="form-group">
                            <span className="form-control-icon" onClick={handleDateIconClick} ><img src={calenderIcon}></img></span>
                            <DatePicker
                            ref={datepickerRef}
                            className="form-select date-picker form-control"
                            placeholderText="Date of Birth"
                            selected={formData.dob}
                            onChange={(date) => handleSelectedDate(date)} 
                            />
                        </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Mobile Number" value={formData.mobileno ?? ''} name="mobileno" onChange={handleFormChange}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Official Email ID" value={formData.email} name="email" onChange={handleFormChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex dashboard-form-row three-columns">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label><strong>Marital Status</strong></label>
                                <div className="radio-group">
                                    <label><input type="checkbox" name="maritalstatus" checked={maritalstatusSingle} value="single" onChange={handleFormChange}/> <span>Single</span></label>
                                    <label><input type="checkbox" name="maritalstatus" checked={maritalstatusMarried} value="married" onChange={handleFormChange}/>
                                        <span>Married</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 form-add-button">
                            {/* <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal"
                            data-bs-target="#authorizedBasicDetailsModal" disabled={!isValid } onClick={handleFormSubmit}> */}
                            {isEditButtonClicked ? (
                                <>
                            <button type="button" className="btn btn-primary mt-3" disabled={!isValid } onClick={handleFormUpdate}>Update</button> &nbsp;
                            <button type="button" className="btn btn-primary mt-3" disabled={!isValid } onClick={() => isSetEditButtonClicked(false)}>Cancel</button>
                                </>
                        ) : (
                            <button type="button" className="btn btn-primary mt-3" disabled={!isValid } onClick={handleFormSubmit}>Add</button>
                            )}
                        </div>
                    </div>
                    
                    <AuthorizedBasicDetailsModal formData={formData}/>
                </div>
            </form>
            ) }
        </div>
    )
}