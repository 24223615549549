import React, {useState, useCallback} from 'react';
import { useMultiStepForm } from '../../../hooks/useMultiStepForm.ts';
import AuthorizedBasicDetails from './AuthorizedBasicDetails.js';
import AuthorizedSignatoryKYC from './AuthorizedSignatoryKYC.js';
import DirectorsBasicInfo from './DirectorsBasicInfo.js';
import DirectorsKYC from './DirectorsKYC.js';
import { useNavigate } from 'react-router-dom';

export default function ManagementKYC() {
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const navigate = useNavigate()

    const setStepValid = useCallback((valid) => {
        setIsCurrentStepValid(valid);
    });

    const steps = [
        { component: <AuthorizedBasicDetails onNext={nextPage} setStepValid={setStepValid} />, name: "AuthorizedBasicDetails" },
        { component: <AuthorizedSignatoryKYC onNext={nextPage} setStepValid={setStepValid} />, name: "AuthorizedSignatoryKYC" },
        { component: <DirectorsBasicInfo onNext={nextPage} setStepValid={setStepValid} />, name: "DirectorsBasicInfo" },
        { component: <DirectorsKYC onNext={nextPage} setStepValid={setStepValid} />, name: "DirectorsKYC" }
    ];

    const { step, isFirstStep, isLastStep, back, next, currentStepIndex } = useMultiStepForm(
        steps.map(s => s.component)
    );

    // Get the current step name for activating icons and links
    const currentStepName = steps[currentStepIndex]?.name;

    function nextPage() {
        if (!isLastStep) return next();
        navigate('/dashboard/onboarding/business-financials')
    }

    return (
        <div className="subcontent-wrapper">
            <div className="title">
                <h3>Management KYC <span className="inprogress-text text-primary">In Progress</span></h3>
            </div>
            <div className="inner-dashboard">
                <div className="left-navtabs">
                    <ul className="nav flex-column">
                        <li className={`nav-item ${["AuthorizedBasicDetails", "AuthorizedSignatoryKYC"].includes(currentStepName) ? "active" : ""}`}>
                            <i className={`far fa-clock ${["AuthorizedBasicDetails", "AuthorizedSignatoryKYC"].includes(currentStepName) ? "active-clock" : ""}`}></i>
                            <a className="nav-link" href="#" style={{ color: ["AuthorizedBasicDetails", "AuthorizedSignatoryKYC"].includes(currentStepName) ? "#3276E8" : "#5A5A5A" }}>
                                Authorized Signatory
                            </a>
                        </li>
                        <li className={`nav-item ${["DirectorsBasicInfo", "DirectorsKYC"].includes(currentStepName) ? "active" : ""}`}>
                            <i className={`far fa-clock ${["DirectorsBasicInfo", "DirectorsKYC"].includes(currentStepName) ? "active-clock" : ""}`}></i>
                            <a className="nav-link" href="#" style={{ color: ["DirectorsBasicInfo", "DirectorsKYC"].includes(currentStepName) ? "#3276E8" : "#5A5A5A" }}>
                                Directors KYC
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="right-form-content">
                    {step}
                </div>
            </div>
            <div className="nextpage-section">
                {!isFirstStep && (
                    <input
                        type="submit"
                        onClick={back}
                        className="btn btn-outline-secondary btn nextbtn"
                        value="PREVIOUS"
                    />
                )}
                <input
                    type="submit"
                    onClick={() => nextPage()}
                    className="btn btn-secondary btn nextbtn"
                    value="NEXT"
                    disabled={!isCurrentStepValid}
                />
            </div>
        </div>
    );
}
