import React, { useState, useEffect, useRef } from 'react';
import cibilImg from './../../../assets/images/cibil-img.png';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';
import axios from 'axios';


function BusinessVerification({onNext, setStepValid}) {
    const [docType, setDocType] = useState('');
    const [docNumber, setDocNumber] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [documents, setDocuments] = useState([]); // To store fetched document data
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);

    const storeAPI = 'http://tb.binarycoders.in/api/document';
    const viewAPI = 'http://tb.binarycoders.in/api/document/show';

    const documentTypes = [
        { value: "1", label: "Company PAN" },
        { value: "2", label: "Aadhar Card" },
        { value: "3", label: "Driving License" },
    ];

    // Callback to handle file selection in DocumentUploadCard
    const handleFilesSelected = (files) => {
        setUploadedFiles(files); // Set selected files
    };

    const handleFormReset = () => {
        formRef.current.reset(); // Reset the form fields
    }

    const handleStepValid = () => {
        
        if (documents.length > 0) {
            setStepValid(true); // Set step as valid
        } else {
            setStepValid(false); // Set step as invalid
        }
    }

    // Check and hide document types that are already uploaded
    const filterDocumentTypes = () => {
        return documentTypes.filter((type) => {
            // Check if any document's doctype matches the current type value
            return !documents.some((doc) => doc.doctype === type.value);
        });
    };

    // Function to get documents based on company ID (cid)
    const getBusinessDocuments = async (cid) => {
        setLoading(true); // Set loading state before fetching
        try {
            const response = await axios.get(viewAPI + '?cid=' + cid);
            console.log("Response : ",response);
            setDocuments(response.data.data); // Store fetched documents
            console.log('Fetched documents:', response.data.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
            alert('Failed to fetch documents. Please try again.');
        } finally {
            setLoading(false); // Set loading state to false after fetching
        }
        
    };

    // Call the getDoc function when the component mounts
    useEffect(() => {
        const companyId = localStorage.getItem('company_id'); // Replace with actual company ID
        getBusinessDocuments(companyId);
    }, []);
    
    useEffect(()=>{
        handleStepValid(); // Check if the step is valid
    },[documents])
    
    // Handle form submission
    const handleSubmit = async (e) => {
        let companyId = localStorage.getItem('company_id');
        let userId = localStorage.getItem('user_id');
        e.preventDefault();
        const formData = new FormData();
        
        // Append form fields
        formData.append('uid', userId); // Assuming user ID is 1, change accordingly
        formData.append('cid', companyId); // Assuming company ID is 123, change accordingly
        formData.append('docno', docNumber);
        formData.append('doctype', docType);

        // Append each uploaded file
        if (uploadedFiles.length > 0) {
            uploadedFiles.forEach((file, index) => {
                formData.append('docfile', file);
            });
        }

        try {
            const response = await axios.post(storeAPI, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                
                console.log('File upload successful:', response.data);
                alert('Document uploaded successfully!');
                
                 // Re-fetch documents to update the table
                getBusinessDocuments(companyId);
                
                handleFormReset(); // Reset the form fields
                onNext(); // Move to the next step
            }

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('File upload failed. Please try again.');
        }
    };



    return (
        <>
        <div className="documents-section">
            <h3>Business Verification</h3>
            <ul className="nav nav-tabs">
                <li key={1} className="nav-item d-flex align-items-center">
                    <a className="nav-link active" data-bs-toggle="tab" href="#PAN">PAN</a>
                    <i className="far fa-clock"></i>
                </li>
                <li key={2} className="nav-item d-flex align-items-center">
                    <a className="nav-link" data-bs-toggle="tab" href="#CIBIL">CIBIL</a>
                    <i className="far fa-clock"></i>
                </li>
            </ul>
            
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="col-md-12 tab-content">
                        <div id="PAN" className="tab-pane active">
                            <div className="d-flex dashboard-form-row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select 
                                            className="form-select form-control" 
                                            value={docType} 
                                            onChange={(e) => setDocType(e.target.value)}
                                        >
                                            <option value="">Select Document Type</option>
                                            {filterDocumentTypes().map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Document Number" 
                                            value={docNumber}
                                            onChange={(e) => setDocNumber(e.target.value)} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex dashboard-form-row">
                                <div className="form-group">
                                    <DocumentUploadCard onFilesSelected={handleFilesSelected} />
                                </div>
                            </div>
                        </div>
                        <div id="CIBIL" className="tab-pane">
                            <div className="d-flex dashboard-form-row">
                                <img className="cibil-img" src={cibilImg} alt="CIBIL" />
                            </div>
                        </div>
                        <div className="col-md-12 form-add-button">
                            <button type="button" className="btn btn-secondary me-5" onClick={onNext}>Skip</button>
                            <button type="submit" className="btn btn-primary">Add</button>
                        </div>
                    </div>
                </form>
            
        </div>
          {/* Render the fetched documents */}
          {
             documents.length > 0 && (
                <div className="document-list">
                <h4>Uploaded Documents</h4>
                {loading ? (
                    <p>Loading documents...</p>
                ) : (
                  <div className="documents-table mt-5">
                    <table>
                      <tr>
                          <th>Document Type</th>
                          <th>Document Number</th>
                          <th>View</th>
                      </tr>
                        {documents.map((doc) => (
                            <tr className='verificationDocs'>
                              <td key={doc.id}>
                                <strong>Type:</strong> {doc.doctype}</td>
                                <td><strong>Number:</strong> {doc.docno}</td>
                                <td><a href={doc.docfile} target="_blank" rel="noopener noreferrer">
                                    View File
                                </a></td>
                            </tr>
                        ))}
                    </table>
                    </div>
                )}
                </div>
             )
          }
          
      </>
    );
}

export default BusinessVerification;
