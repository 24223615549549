export default function PanVerifiedCard() {
    return (
        <div className="col-md-6 border col">
            <span className="verified-text"><i className="far fa-clock"></i> Verified</span>
            <div className="info">
                <h3>PAN</h3>
                <h5>xxxx xxxx xxxx xxxx</h5>
                <p>ID Proof</p>
            </div>
            <div className="col-md-3 icons-wrap">
                <span className="icons"><i className="fas fa-edit"></i></span>
                <span className="icons"><i className="fas fa-trash"></i></span>
            </div>
        </div>
    )
}