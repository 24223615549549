import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment';
import calenderIcon from '../../../assets/images/icons/calendar.svg';
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';
import BankStatementList from './BankStatementList';


export default function UploadBankStatement({onNext, setStepValid}) {
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [statementFrom, setStatementFrom] = useState('');
    const [statementTo, setStatementTo] = useState('');
    const [uploadedData, setUploadedData] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    
    const datepickerRefOne = useRef(null);
    const datepickerRefTwo = useRef(null);

    const storeAPI = 'http://tb.binarycoders.in/api/bankStatement'; // Example API for storing bank statement data
    const updateAPI = 'http://tb.binarycoders.in/api/bankStatement/update?id='; // Example API for updating bank statement data
    const getAPI = 'http://tb.binarycoders.in/api/bankStatement/show?cid='; // Example API for retrieving bank statement data

    const handleFilesSelected = (files) => {
        setUploadedFiles(files); // Set selected files
    };

    const handleDateIconFromClick = () => {
        datepickerRefOne.current.setOpen(true);
    }
    
    const handleDateIconToClick = () => {
        datepickerRefTwo.current.setOpen(true);
    }

    // Function to handle form submission
    const handleAdd = async () => {
        if (!bankName || !accountNumber || !statementFrom || !statementTo || !uploadedFiles) return;
        let userId = localStorage.getItem('user_id'); 
        let companyId = localStorage.getItem('company_id'); 

        try {
            // Prepare form data for uploading
            const formData = new FormData();
            formData.append('uid', userId);
            formData.append('cid', companyId);
            formData.append('name', bankName);
            formData.append('accno', accountNumber);
            formData.append('from', moment(statementFrom).format('YYYY-MM-DD'));
            formData.append('to', moment(statementTo).format('YYYY-MM-DD'));

            // Append each uploaded file
            if (uploadedFiles.length > 0) {
                formData.append('doc', uploadedFiles[0]);
            }

            // Make API call with axios to upload file and data
            const response = await axios.post(storeAPI, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Fetch updated list after successful upload
            getBankStatements();

            // Reset form fields
            setBankName('');
            setAccountNumber('');
            setStatementFrom('');
            setStatementTo('');
            setUploadedFiles(null);
            
            onNext();
        } catch (error) {
            console.error('Error uploading bank statement', error);
        }
    };

    // Disable "Add" button if form is incomplete
    const isFormComplete = bankName && accountNumber && statementFrom && statementTo && uploadedFiles;

    // Handle editing
    const handleEdit = (id) => {

        console.log('selectedData', id);
        const selectedData = uploadedData.find(data => data.id === id); // Assuming you have an array of bank statements
        console.log('uploadedData', selectedData);
        setBankName(selectedData.name);
        setAccountNumber(selectedData.accno);
        setStatementFrom(selectedData.from);
        setStatementTo(selectedData.to);
        setUploadedFiles([selectedData.doc]); // Assuming you handle file edit functionality accordingly
        setIsEditing(true);
        setEditId(id);
    };

    // Handle deleting an uploaded entry
    const handleDelete = (index) => {
        const updatedData = [...uploadedData];
        updatedData.splice(index, 1);
        setUploadedData(updatedData);
    };

    const handleCancel = () => {
        setBankName('');
        setAccountNumber('');
        setStatementFrom('');
        setStatementTo('');
        setUploadedFiles(null);
        setIsEditing(false);
    }

    const handleUpdate = async () => {
        console.log('id', editId);
        
        if (!bankName || !accountNumber || !statementFrom || !statementTo || !uploadedFiles) return;
        let userId = localStorage.getItem('user_id'); 
        let companyId = localStorage.getItem('company_id'); 
        
        try {
            // Prepare form data for uploading
            const formData = new FormData();
            formData.append('uid', userId);
            formData.append('cid', companyId);
            formData.append('name', bankName);
            formData.append('accno', accountNumber);
            formData.append('from', moment(statementFrom).format('YYYY-MM-DD'));
            formData.append('to', moment(statementTo).format('YYYY-MM-DD'));

            // Append each uploaded file
            if (uploadedFiles.length > 0) {
                formData.append('doc', uploadedFiles[0]);
            }
            console.log('formData', formData.get('doc'));

            // Make API call with axios to upload file and data
            const response = await axios.post(updateAPI + editId, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Fetch updated list after successful upload
            getBankStatements();

            // Reset form fields
            setBankName('');
            setAccountNumber('');
            setStatementFrom('');
            setStatementTo('');
            setUploadedFiles(null);
            onNext();
        } catch (error) {
            console.error('Error uploading bank statement', error);
        }
    }

    // Function to fetch bank statement data
    const getBankStatements = () => {
        let companyId = localStorage.getItem('company_id');
        axios.get(getAPI + companyId)
            .then(response => {
                const data = response.data.data; // Assuming data is an array of bank statements
                setUploadedData(data); 
                if (data.length > 0) {
                    setStepValid(true);
                }
            })
            .catch(error => {
                console.error('Error fetching bank statements:', error);
            });
    };

    useEffect(() => {
        getBankStatements();
    }, []);

    return (
        <div className="documents-section">
            <div className='d-flex'>
                <h3>Upload Bank Statement <span style={{fontSize: '14px'}}>({uploadedData.length} year(s) statement)</span></h3>
                {/* <h5 className="ms-auto"><i className="fas fa-plus text-primary"></i> Add New Account</h5> */}
            </div>
            <form>
                <div className="d-flex dashboard-form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                        <span className="form-control-icon">
                                <img src={arrowDownIcon} alt="Arrow Down" />
                            </span>
                            
                            <select className="form-control" placeholder="Bank Name"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}>
                                <option value="">Select Bank Name</option>
                                <option value="Axis Bank">Axis Bank</option>
                                <option value="HDFC Bank">HDFC Bank</option>
                                <option value="ICICI Bank">ICICI Bank</option>
                                <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Account Number"
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex dashboard-form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <span className="form-control-icon" onClick={handleDateIconFromClick}>
                                <img src={calenderIcon} alt="Calendar Icon" />
                            </span>
                            <DatePicker
                                className="form-control"
                                selected={statementFrom}
                                onChange={(date) => setStatementFrom(date)}
                                placeholderText="Select Statement From Date"
                                dateFormat="yyyy-MM-dd"
                                ref={datepickerRefOne}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <span className="form-control-icon" onClick={handleDateIconToClick}>
                                <img src={calenderIcon} alt="Calendar Icon" />
                            </span>
                            <DatePicker
                                className="form-control"
                                selected={statementTo}
                                onChange={(date) => setStatementTo(date)}
                                placeholderText="Select Statement To Date"
                                dateFormat="yyyy-MM-dd"
                                ref={datepickerRefTwo}
                            />
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <DocumentUploadCard onFilesSelected={handleFilesSelected} />
                </div>
                <div className="col-md-12 form-add-button">
                    {isEditing ? (
                        <>
                            <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleUpdate}
                            disabled={!isFormComplete}
                        >
                            Update
                        </button> 
                        &nbsp;
                        <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        </>
                    ) : (<button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAdd}
                        disabled={!isFormComplete}
                    >
                        Add
                    </button>) }
                    
                </div>
            </form>

            <BankStatementList handleEdit={handleEdit} handleDelete={handleDelete} isHeading={true}/>
        </div>
    );
}
