import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import editIcon from '../../../assets/images/icons/edit.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import buildingIcon from '../../../assets/images/icons/building.svg';
import calenderIcon from '../../../assets/images/icons/calendar.svg';
import DatePicker from "react-datepicker";
import Edit from '../../../assets/images/edit.png'
import Delete from '../../../assets/images/delete.png'
import Preview from '../../../assets/images/preview.png'
import Clipboard from '../../../assets/images/clipboard-tick.png'
import moment from 'moment';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';


function Documents() {
    

    const [docType, setDocType] = useState(null);
    const [docNumber, setDocNumber] = useState('');
    const [docFile, setDocFile] = useState(null);
    const [docIssueDate, setDocIssueDate] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [cinDetails, setCinDetails] = useState([]);
    const [moaDetails, setMoaDetails] = useState([]);
    const [aoaDetails, setAoaDetails] = useState([]);

    const [businessAddressResult, setBusinessAddressResult] = useState([]);
    const [isAddBtnClicked, setIsAddBtnClicked] = useState(false);
    const [companyDetailsResult, setCompanyDetailsResult] = useState([]);
    const [tCompanyName, setTCompanyName] = useState('');
    const [cinCompleted , setCinCompleted] = useState(false)
    const [moaCompleted , setMoaCompleted] = useState(false)
    const [aoaCompleted , setAoaConpleted] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const datepickerRef = useRef(null);

    const handleDocIssueDateChange = (date) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setDocIssueDate(formattedDate);
      // setDob(formattedDate);
    };

    const handleDocFileChange = (e) => {
        setDocFile(e);
    };

    const handleDocTypeChange= (e) => {
        setDocType(e.target.value);
    }
    
    const handleDocNumberChange= (e) => {
        setDocNumber(e.target.value);
    }

    const docTypeTab = (docType) => {
        setDocType(docType);
    }

    const handleFilesSelected = (files) => {
      setUploadedFiles(files); // Set selected files
  };
    
    const handleSubmit = () => {
        var companyId = localStorage.getItem('company_id');
        var userId = localStorage.getItem('user_id');
        var storeDocAPI = 'http://tb.binarycoders.in/api/cin';
        const currentDate = new Date().toISOString().slice(0, 10); 
        // Prepare data to send
        const formData = new FormData();
        formData.append('cid', companyId);
        formData.append('uid', userId);
        formData.append('verified', '1');
        formData.append('status', '1');


        if (docType === 'CIN') {
            console.log(typeof uploadedFiles , 'uploaded')
            // formData.append('doc', docFile); // Ensure this matches with upload.single('fileCIN') in backend
            if (uploadedFiles.length > 0){
              formData.append('doc',uploadedFiles[0])
            } 
            formData.append('documentTypeCIN', docType);
            formData.append('docno', docNumber);
            formData.append('docidate', docIssueDate);
            formData.append('ludate', docIssueDate);
        }
        if (docType === 'MOA') {
            storeDocAPI = 'http://tb.binarycoders.in/api/moa';
            if (uploadedFiles.length > 0){
              formData.append('moadoc',uploadedFiles[0])
            } 
            // formData.append('moadoc', docFile); // Ensure this matches with upload.single('fileMOA') in backend
            formData.append('documentTypeMOA', docType);
            formData.append('moano', docNumber);
            const finalDocIdate = currentDate;
            formData.append('ludate', currentDate);

        }
        if (docType === 'AOA') {
            storeDocAPI = 'http://tb.binarycoders.in/api/aoa';
            // formData.append('aoadoc', docFile); // Ensure this matches with upload.single('fileAOA') in backend
            if (uploadedFiles.length > 0){
              formData.append('aoadoc',uploadedFiles[0])
            } 
            formData.append('documentTypeAOA', docType);
            formData.append('aoano', docNumber);
            formData.append('ludate', currentDate);
        }
    
        // Send data to the backend API
        fetch(storeDocAPI, {
            method: 'POST',
            body: formData,
            
        })
        .then(response => {
            getCinDetails();
            getMoaDetails();
            getAoaDetails();
            // Handle success
            console.log(response.data);
            
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        });
    };

    const handleDocEdit = (docT) => {
        setIsEditMode(true);
        if (docT === 'CIN') {        
            setDocIssueDate(cinDetails.docidate);
            setDocNumber(cinDetails.docno);
            setDocType('CIN');
        }

        if (docT === 'MOA') {
            setDocIssueDate(moaDetails.ludate);
            setDocNumber(moaDetails.moano);
            setDocType('MOA');
        }

        if (docT === 'AOA') {
            setDocIssueDate(aoaDetails.ludate);
            setDocNumber(aoaDetails.aoano);
            setDocType('AOA');
        }


    }

    const getCinDetails = async () => {
    
        const cid = localStorage.getItem("company_id");
        const uid = localStorage.getItem("user_id");
    
         await axios.get('http://tb.binarycoders.in/api/cin/show', {
          params:{cid:cid}
        })  
        .then((res) => {
          if (res.status === 201 && Object.keys(res?.data?.data).length > 0) {
            setCinDetails(res.data.data);
            setCinCompleted(true)
          }
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    
        if(cinDetails.length > 0)
          console.log('Business address', cinDetails);
              
      };
    
      const getMoaDetails = async () => {
    
        const cid = localStorage.getItem("company_id");
        const uid = localStorage.getItem("user_id");
    
         await axios.get('http://tb.binarycoders.in/api/moa/show', {
          params:{cid:cid}
        })  
        .then((res) => {
          if (res.status === 201 && Object.keys(res?.data?.data)?.length > 0) {
            setMoaDetails(res.data.data);
            setMoaCompleted(true)
          }
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    
        if(moaDetails.length > 0)
          console.log('Moa details', moaDetails);
              
      };
      
      const getAoaDetails = async () => {
    
        const cid = localStorage.getItem("company_id");
        const uid = localStorage.getItem("user_id");
    
         await axios.get('http://tb.binarycoders.in/api/aoa/show', {
          params:{cid:cid}
        })  
        .then((res) => {
          if (res.status === 201 && Object.keys(res?.data?.data)?.length > 0) {
            setAoaDetails(res.data.data);
            setAoaConpleted(true)
            
          }
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    
        if(aoaDetails.length > 0)
          console.log('Business address', aoaDetails);
              
      };

      const handleDateIconClick = () => {
        datepickerRef.current.setOpen(true);
    }
    
    
    // const handleSubmit = () => {
    //     const uid = localStorage.getItem('user_id');
    //     const cid = localStorage.getItem('company_id');
    
    //     const formData = new FormData();
    //     formData.append('uid', uid);
    //     formData.append('cid', cid);
    //     formData.append('docno', documentNumberCIN);
    //     formData.append('docidate', documentIssueDateCIN);
    //     formData.append('file', fileCIN);
    
    //     fetch('http://localhost:8081/api/insertCINDocument', {
    //         method: 'POST',
    //         body: formData,
    //     })
    //     .then(response => {
    //         // Handle success
    //         console.log(response.data);
    //     })
    //     .catch(error => {
    //         // Handle error
    //         console.error('Error:', error);
    //     });
    // };

    const getBusinessAddress = async () => {
    
        const cid = localStorage.getItem("company_id");
    
         await axios.get('http://tb.binarycoders.in/api/businessAddress/show', {
          params:{cid:cid}
        })  
        .then((res) => {
          if (res.status === 201) {
            setBusinessAddressResult(res.data.data);
            setIsAddBtnClicked(false);
            console.log('console business address', res.data);
            
          }
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    
        if(businessAddressResult.length > 0)
          console.log('Business address', businessAddressResult);
              
      };

      const getCompanyDetails = async () => {
        const uid = localStorage.getItem("user_id");
        const companyName = localStorage.getItem('company_name');
        setTCompanyName(companyName);
        if (!uid) {
          console.error("User ID is missing.");
          return;
        }
      
        axios.get('http://tb.binarycoders.in/api/business/show',{
          params: { uid: uid }
        })
        .then((res) => {
            setCompanyDetailsResult(res.data.data);
          
          
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
      };
    
    useEffect(() => {
        getCinDetails();
        getMoaDetails();
        getAoaDetails();
        getCompanyDetails();
        getBusinessAddress();
    }, []);

    useEffect(() => {
        if (docType === 'CIN' && docFile && docNumber !== '' && docIssueDate !== '') {
            setIsValid(true);
        } else if (docType === 'MOA' && docFile && docNumber !== '') {
            setIsValid(true);
        } else if (docType === 'AOA' && docFile && docNumber !== '') {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [docType, docFile, docNumber, docIssueDate]);

    return (
        <>
        <div className="documents-section">
            {((cinDetails.length == 0 || moaDetails.length == 0 || aoaDetails.length == 0) || isEditMode ) && (
              <>
              <div className='documentWrapper'>
                <h3>Documents</h3>
                <ul className="nav nav-tabs">
                    <li className="nav-item d-flex align-items-center">
                        <a className="nav-link active" data-bs-toggle="tab" onClick={() => docTypeTab('CIN')} href="#CIN">CIN</a>
                        {cinCompleted ? <i className="fa-regular fa-circle-check" style={{color:'#14A800'}}></i>:
                          <i className="far fa-clock" 
                          style={{color:`${docType === "CIN" ? "#C87A7A" : "#969696"}`}}></i>  }
                    </li>
                    <li className="nav-item d-flex align-items-center">
                        <a className="nav-link" data-bs-toggle="tab" onClick={() => docTypeTab('MOA')} href="#MOA">MOA</a>
                        {moaCompleted ?<i className="fa-regular fa-circle-check" style={{color:'#14A800'}}></i>:
                        <i className="far fa-clock" 
                          style={{color:`${docType === "MOA" ? "#C87A7A" : "#969696"}`}}></i>  
                          }
                    </li>
                    <li className="nav-item d-flex align-items-center">
                        <a className="nav-link" data-bs-toggle="tab" onClick={() => docTypeTab('AOA')} href="#AOA">AOA</a>
                        {aoaCompleted ? <i className="fa-regular fa-circle-check" style={{color:'#14A800'}}></i> : <i className="far fa-clock" style={{color:`${docType === "AOA" ? "#C87A7A" : "#969696"}`}}></i>}
                    </li>
                </ul>
              </div>
              <form encType='multipart/form-data'>
                  <div className="col-md-12 tab-content">
                      <div id="CIN" className="tab-pane active">
                          <div className="d-flex dashboard-form-row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <select className="form-select form-control" onChange={handleDocTypeChange} value={docType}>
                                          <option value="">Select Document Type</option>
                                          {cinDetails.length == 0 || (isEditMode && docType == 'CIN') ? (<option value="CIN">Certificate of Incorporation (CIN)</option>): ''} 
                                          {moaDetails.length == 0 || (isEditMode && docType == 'MOA') ? (<option value="MOA">Memorandum of Association (MOA)</option>): ''} 
                                          {aoaDetails.length == 0 || (isEditMode && docType == 'AOA') ? (<option value="AOA">Articles of Association (AOA)</option>): ''} 
                                      </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <input type="text" className="form-control" value={docNumber} placeholder="Document Number" onChange={handleDocNumberChange} />
                                  </div>
                              </div>
                              
                          </div>
                          <div className="d-flex dashboard-form-row">
                          {(docType === 'CIN' || docType === 'MOA') && (<div className="col-md-6">
                              <div className="form-group">
                              <span className="form-control-icon" onClick={handleDateIconClick} ><img src={calenderIcon}></img></span>
                              <DatePicker
                            ref={datepickerRef}
                            className="form-select date-picker form-control"
                            
                            placeholderText="Document Issue Date"
                            selected={docIssueDate}
                            onChange={(date) => handleDocIssueDateChange(date)} 
                            />
                                  {/* <input type="date" className="form-control" placeholder="Document Issue Date" value={docIssueDate} onChange={handleDocIssueDateChange} /> */}
                              </div>

                              </div>)}
                          </div>
                          <div className="d-flex dashboard-form-row">
                              <div className="form-group">
                                  {/* <input type="file" className="form-control file-upload-control" onChange={handleDocFileChange} /> */}
                                  <DocumentUploadCard onFilesSelected={handleFilesSelected}/>
                              </div>
                          </div>
                      </div>
                      <div id="MOA" className="tab-pane">
                          <div className="d-flex dashboard-form-row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <select className="form-select form-control" onChange={handleDocTypeChange}>
                                          <option value="">Select Document Type</option>
                                          {cinDetails.length == 0 ? (<option value="CIN">Certificate of Incorporation (CIN)</option>): ''} 
                                          {moaDetails.length == 0 ? (<option value="MOA">Memorandum of Association (MOA)</option>): ''} 
                                          {aoaDetails.length == 0 ? (<option value="AOA">Articles of Association (AOA)</option>): ''} 
                                      </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <input type="text" className="form-control" placeholder="UXYZZEF546897852" onChange={handleDocNumberChange} />
                                  </div>
                              </div>
                          </div>
                          <div className="d-flex dashboard-form-row">
                              <div className="form-group">
                                  {/* <input type="file" className="form-control file-upload-control" onChange={handleDocFileChange} /> */}
                                  <DocumentUploadCard onFilesSelected={handleFilesSelected}/>
                              </div>
                          </div>
                      </div>
                      <div id="AOA" className="tab-pane">
                          <div className="d-flex dashboard-form-row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <select className="form-select form-control" onChange={handleDocTypeChange}>
                                          <option value="">Select Document Type</option>
                                          {cinDetails.length == 0 ? (<option value="CIN">Certificate of Incorporation (CIN)</option>): ''} 
                                          {moaDetails.length == 0 ? (<option value="MOA">Memorandum of Association (MOA)</option>): ''} 
                                          {aoaDetails.length == 0 ? (<option value="AOA">Articles of Association (AOA)</option>): ''} 
                                      </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <input type="text" className="form-control" placeholder="UXYZZEF5468972" onChange={handleDocNumberChange} />
                                      
                                  </div>
                              </div>
                          </div>
                          <div className="d-flex dashboard-form-row">
                              <div className="form-group">
                                  {/* <input type="file" className="form-control file-upload-control" onChange={handleDocFileChange} /> */}
                                  <DocumentUploadCard onFilesSelected={handleFilesSelected}/>
                              </div>
                          </div>
                          
                      </div>
                      <div className="col-md-12 form-add-button">
                          <button type="button" className="btn btn-secondary me-5">Skip</button>
                          
                          <button type="button" className="btn btn-primary" onClick={handleSubmit}>{isEditMode ? 'Update' : 'Add'}</button>
                          &nbsp;
                          <button type="button" className="btn btn-secondary" onClick={() => setIsEditMode(false)}>Cancel</button>

                      </div>
                  </div>
              </form>
            </>
            )}
            {(cinDetails.length != 0 || moaDetails.length != 0 || aoaDetails.length != 0) && (

                <div className="documents-table mt-5">
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Last Edited</th>
                                <th>Editor</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cinDetails.length != 0 && (<tr>
                                <td><img src={Clipboard}/> <span className='documentTableHead'>Certificate of Incorporation</span></td>
                                <td><span className='documentData'>{cinDetails.docidate}</span></td>
                                <td><span className='documentData'>{cinDetails.docno}</span></td>
                                <td><img src="https://demo.dashboardpack.com/adminty-html/files/assets/images/avatar-4.jpg"
                                    alt="" className="avatar-img" /></td>
                                <td style={{padding:'0'}}>
                                    <div className="icons documentIconWrapper">
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <a href={cinDetails.doc} target='_blank'>
                                            <img src={Preview}></img>
                                          </a>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}} onClick={() => handleDocEdit('CIN')}>
                                          <img src={Edit} height={19} width={19}></img>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <img src={Delete} height={20} width={14}></img> 
                                        </div>
                                    </div>
                                </td>
                            </tr>)}
                            
                            {moaDetails != 0 && (<tr>
                                <td><img src={Clipboard}/><span className='documentTableHead'> Memorandum of
                                    Association (MOA)</span></td>
                                <td>{moaDetails.ludate}</td>
                                <td>{moaDetails.moano}</td>
                                <td><img src="https://demo.dashboardpack.com/adminty-html/files/assets/images/avatar-4.jpg"
                                    alt="" className="avatar-img" /></td>
                                <td style={{padding:'0'}}>
                                    <div className="icons documentIconWrapper">
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <a href={moaDetails.mocdoc} target='_blank'>
                                            <img src={Preview}></img>
                                          </a>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}} onClick={() => handleDocEdit('MOA')}>
                                          <img src={Edit} height={19} width={19}></img>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <img src={Delete} height={20} width={14}></img> 
                                        </div>
                                    </div>
                                </td>
                            </tr>)}
                            {aoaDetails != 0 && (<tr>
                                <td><i className="fas fa-clipboard"></i><span className='documentTableHead'> Articles of Association
                                    (AOA)</span></td>
                                <td>{aoaDetails.ludate}</td>
                                <td>{aoaDetails.aoano}</td>
                                <td><img src="https://demo.dashboardpack.com/adminty-html/files/assets/images/avatar-4.jpg"
                                    alt="" className="avatar-img" /></td>
                                <td>
                                    <div className="icons documentIconWrapper">
                                          <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <a href={aoaDetails.aoadoc} target='_blank'>
                                            <img src={Preview}></img>
                                          </a>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}} onClick={() => handleDocEdit('AOA')}>
                                          <img src={Edit} height={19} width={19}></img>
                                        </div>
                                        <div style={{paddingRight:'0.5rem' , paddingLeft:'0.5rem'}}>
                                          <img src={Delete} height={20} width={14}></img> 
                                        </div>
                                    </div>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        
        {(companyDetailsResult.length != 0) && (
            <>
        <h3>Company Details</h3>
        <div className="modal-content company-detail-modal mb-5">
          <h2> 
            <span className="company-title"><img src={buildingIcon}/></span> {tCompanyName}
          </h2>
            <div className="col-md-12 d-flex">
                <div className="col-md-11">
                  
                  <table  className="companyDetailsTableWrapper">
                    <tbody>
                      <tr>
                        <td>Date:</td>
                        <td>{moment(companyDetailsResult?.incordate).format('DD-MM-YYYY')}</td>
                      </tr>
                      <tr>
                        <td>Company Type:</td>
                        <td>{companyDetailsResult?.ctype}</td>
                      </tr>
                      <tr>
                        <td>Nature of Business:</td>
                        <td>{companyDetailsResult?.busnature}</td>
                      </tr>
                      <tr>
                        <td>Industry Type:</td>
                        <td>{companyDetailsResult?.indtype}</td>
                      </tr>
                      <tr>
                        <td>Collateral:</td>
                        <td>{companyDetailsResult?.cltype}</td>
                      </tr>
                      <tr>
                        <td>Memorandum of Association (MOA):</td>
                        <td>{companyDetailsResult?.moa ? 'Completed' : 'Pending'}</td>
                      </tr>
                      <tr>
                        <td>Articles of Association (AOA):</td>
                        <td>{companyDetailsResult?.aoa ? 'Completed' : 'Pending'}</td>
                      </tr>
                      <tr>
                        <td>Certificate of Incorporation (CIN):</td>
                        <td>{companyDetailsResult?.cin ? 'Completed' : 'Pending'}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="companyIconContainer">
                    <span className="icons">
                        <img src={deleteIcon} alt="Delete" />
                      </span>
                    <span className="icons">
                        <img src={editIcon} alt="Edit" />
                      </span>
                  </div>
              
            </div>
        </div>
        </>
        )}

        {(businessAddressResult.length > 0) && (
        <>
        <h3>Business Address</h3>
        <div className="modal-content company-detail-modal mb-5">
          <h2>Business Address:</h2>
          {businessAddressResult.length != 0 && (
            <div>
              {businessAddressResult.map((address, index) => (
                 <div 
                  className="col-md-12 d-flex address-entry" 
                  key={index} 
                  style={{
                    // border: "1px solid #ccc", // Add a border
                    // padding: "15px",          // Add padding for a better look
                    marginBottom: "10px",     // Add margin to separate entries
                    borderRadius: "5px",      // Optional: to round the corners
                  }}
                >
                <div className="businessContainer">
                  <div className="businessAddressContainer">
                    <div className="businessWrapper">
                          <span>Address: {address?.address}</span>
                    </div>
                    <div className="businessWrapper">
                          <span>State: {address?.city}</span>
                    </div>
                    <div className="businessWrapper">
                          <span>Postal: {address?.postal}</span>
                    </div>
                  </div>
                  <div className="businessLocationContainer">
                     <span>State: {address.state}</span>
                     <span>Area: {address.area}</span>
                  </div>
                  <div className="iconContainer">
                    <span className="icons">
                      <img src={deleteIcon} alt="Delete" />
                    </span>
                  <span className="icons">
                      <img src={editIcon} alt="Edit" />
                    </span>
                  </div>
                </div>
                </div>
              ))}
            </div>
          )}
        </div>
        </>
        )}
        </>
    )
}

export default Documents;
