import { useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from '../../../assets/images/icons/calendar.svg';
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';
import editIcon from '../../../assets/images/icons/edit.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import buildingIcon from '../../../assets/images/icons/building.svg';
import moment from 'moment';
import Swal from "sweetalert2";
import { ToastContainer , toast } from "react-toastify";

function CompanyDetail({onNext, setStepValid}) {
  const [companyDetailsData, setCompanyDetailsData] = useState([]);
  
  
  const [companyDetailsResult, setCompanyDetailsResult] = useState([]);
  const [tCompanyName, setTCompanyName] = useState('')
  const [selectedDate, setSelectedDate] = useState(null);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isAddBtnClicked, isSetAddBtnClicked] = useState(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    companytype: "",
    businessnature: "",
    industrytype: "",
    collateraltype:"",
  })

  const datepickerRef = useRef(null);

  const loadCompanyTypes = "http://tb.binarycoders.in/api/TmCompanyType";
  const loadBusinessNature = "http://tb.binarycoders.in/api/TmBusinessNature";
  const loadIndustryTypes = "http://tb.binarycoders.in/api/TmIndustryType";
  const loadCollateralTypes = "http://tb.binarycoders.in/api/TmCollateralType";

  const storeCompanyDetails = "http://tb.binarycoders.in/api/business";
  // const updateCompanyDetails = "http://tb.binarycoders.in/api/business/update";
  const deleteCompanyDetails = " http://tb.binarycoders.in/api/business/destroy";

  const promise1 = axios.get(loadCompanyTypes);
  const promise2 = axios.get(loadBusinessNature);
  const promise3 = axios.get(loadIndustryTypes);
  const promise4 = axios.get(loadCollateralTypes);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    
    const newFormData = Object.assign({}, formData, { [name]: value });
    
    setFormData(newFormData);
  };

  const handleDateIconClick = () => {
    datepickerRef.current.setOpen(true);
  }


useEffect(() => {
  if (companyDetailsResult.length != 0) {
    setStepValid(true);
  } else {
    setStepValid(false);
  }
}, [isValid, setStepValid]);

useEffect(() => {
  
  Promise.all([promise1, promise2, promise3, promise4]).then((res) => {
    console.log("All data for dropdown",res);
    setCompanyDetailsData(res);
  });
  getCompanyDetails();
  }, []);

  useEffect(() => {
    if (isEditButtonClicked || companyDetailsResult.length > 0) {
      // Set the form data with companyDetailsResult values
      setFormData({
        companytype: companyDetailsResult.ctypeId,
        businessnature: companyDetailsResult.busnatureId,
        industrytype: companyDetailsResult.indtypeId,
        collateraltype: companyDetailsResult.cltypeId,
      });
      
      // Set the selected date for the date picker
      setSelectedDate(moment(companyDetailsResult.incordate, 'YYYY-MM-DD').toDate());
    }

  }, [isEditButtonClicked, companyDetailsResult]);
  

  useEffect(() => {
    if (isChecked2) {
      const { collateraltype, ...formDataWithoutCollateral } = formData;
      const allDropdownsSelected = Object.values(formDataWithoutCollateral).every(value => value !== "");
      setIsValid(allDropdownsSelected);
    } else if (isChecked1) {
      setIsValid(Object.values(formData).every(value => value !== ""));
    } else {
      setIsValid(false);
    }
  }, [formData, isChecked1, isChecked2]);
  
  const handleCheck1Change = () => {
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
  };

  const handleCheck2Change = () => {
    setIsChecked2(!isChecked2);
    setIsChecked1(false);
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem("user_id");
    axios({
      method: 'post',
      url: storeCompanyDetails,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid: user_id,
        incordate: selectedDate,
        ctype: formData.companytype,
        busnature: formData.businessnature,
        indtype: formData.industrytype,
        cl: 0,
        cltype: formData.collateraltype || "0",
      },

    })
    .then(response => {
      
      // console.log(response.data);
      
      if(response.status == 201) {
        localStorage.setItem("company_id", response.data.id);
        getCompanyDetails();
        onNext();
      }
      
    })
    .catch(error => {
      console.error("Error:", error.response);
    });
  };
  

  const getCompanyDetails = async () => {
    const uid = localStorage.getItem("user_id");
    const companyName = localStorage.getItem('company_name');
    setTCompanyName(companyName);
    if (!uid) {
      console.error("User ID is missing.");
      return;
    }
  
    axios.get('http://tb.binarycoders.in/api/business/show',{
      params: { uid: uid }
    })
    .then((res) => {
          
          setFormData({
            companytype: res.data.data.ctypeId,
            businessnature: res.data.data.busnatureId,
            industrytype: res.data.data.indtypeId,
            collateraltype: res.data.data.cltypeId,
          });
          
          
          setSelectedDate(res.data.data?.incordate);
          if(res.data.data.cltype == 0) {
            setIsChecked2(true);
            setIsChecked1(false);
          } else {
            setIsChecked1(true);
            setIsChecked2(false);
          }
          
          setCompanyDetailsResult(res.data.data);
      console.log('First all effect', res.data.data);
      
    })
    .catch((error) => {
      console.error("Error fetching company details:", error);
    });
  };
  
  
  

  const handleUpdate = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem("user_id");
    const cid = localStorage.getItem("company_id");
    const updateCompanyDetails = `http://tb.binarycoders.in/api/business/update?${user_id}`;

    axios({
      method: 'PUT',
      url: updateCompanyDetails,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid: user_id,
        cid: cid,
        incordate: moment(selectedDate).format('YYYY-MM-DD'),
        ctype: formData.companytype,
        busnature: formData.businessnature,
        indtype: formData.industrytype,
        cl: 0,
        cltype: formData.collateraltype || "0",
      },
    })
    .then(response => {
      
      isSetAddBtnClicked(false);
      setIsEditButtonClicked(false);
      setSelectedDate(companyDetailsResult?.date);   
      getCompanyDetails();   
    })
    .catch(error => {
      console.error("Error:", error.response);
    });
  };

  const handleAddButtonDelete = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem("user_id");
    const cid = localStorage.getItem("company_id");
    axios({
      method: 'DELETE',
      url: deleteCompanyDetails,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid: user_id,
        cid: cid,
      },
    })
    .then(res => {
      if (res.status === 200) {
        Swal.fire({
            icon: 'success',
            title: 'Company Details Deleted Successfully!',
        }).then((result) => {
            if (result.isConfirmed) {
              isSetAddBtnClicked(false);
              setSelectedDate("");
              setFormData("");
            }
          });
        // setTimeout(() => toast.success("Company Details Deleted Successfully!"), 500);
        isSetAddBtnClicked(false);
        setSelectedDate("");
        setFormData("");
      }      
    })
    .catch(error => {
      console.error("Error:", error.response);
    });
  };


  return (
    <>
      {/* <ToastContainer/> */}
      <div>
        <h3>Company Detail</h3>
        {(isEditButtonClicked || companyDetailsResult.length == 0) && (
          <>
            <form>
              <div className="col-md-12">
                <div className="col-md-6 d-flex dashboard-form-row">
                  <div className="form-group">
                    <span className="form-control-icon" onClick={handleDateIconClick}><img src={calenderIcon}></img></span>
                    <DatePicker
                    ref={datepickerRef}
                      className="form-select date-picker form-control"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(moment(date).format('YYYY-MM-DD'))} placeholderText="Incorporation Date"
                    />
                  </div>
                  <div className="form-group"> 
                    <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                    <select className="form-select form-control" value={formData?.companytype} onChange={handleChange} name="companytype" style={{color: formData.companytype ? '#3276E8' : '#5A5A5A'}}>
                        <option value="">Company Type</option>
                        {
                            companyDetailsData[0]?.data.TmCompanyType
                            .map((item) => (
                              <option key={item.ctypeid} value={item.ctypeid}>{item.ctype}</option>
                            ))
                        }
                    </select>
                  </div>
                </div>
                <div className="col-md-6 d-flex dashboard-form-row">
                  <div className="form-group"> 
                    <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                    <select className="form-select form-control" value={formData?.businessnature} onChange={handleChange} name="businessnature" style={{color: formData.businessnature ? '#3276E8' : '#5A5A5A'}}>
                        <option value="">Nature of Business</option>
                        {
                            companyDetailsData[1]?.data.TmBusinessNature.map((item) => (
                              <option key={item.bnid} value={item.bnid}>{item.bn}</option>
                            ))
                        }
                    </select>
                  </div>
                  <div className="form-group">
                  <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                    <select className="form-select form-control" value={formData?.industrytype} onChange={handleChange} name="industrytype" style={{color: formData.industrytype ? '#3276E8' : '#5A5A5A'}}>
                        <option value="">Industry Type</option>
                        {
                            companyDetailsData[2]?.data.TmIndustryType.map((item) => (
                              <option key={item.itid} value={item.itid}>{item.it}</option>
                            ))
                        }
                    </select>
                  </div>
                </div>
                <div className="col-md-6 d-flex dashboard-form-row">
                  <div className="form-group">
                    <label className="collateral-label">
                      <strong>Wish to take loan against collateral?</strong>
                    </label>
                    <div className="radio-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked1}
                          onChange={handleCheck1Change}
                        />
                        <span>Yes</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked2}
                          onChange={handleCheck2Change}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                  {isChecked1 == true && (
                    <div className="form-group">
                      <div className="form-group">
                      <span className="form-control-icon"><img src={arrowDownIcon}></img></span>
                        <select className="form-select form-control" value={formData?.collateraltype} onChange={handleChange} name="collateraltype" style={{color: formData.collateraltype ? '#3276E8' : '#5A5A5A'}}>
                        <option value="">Select Collateral Type</option>
                        {
                            companyDetailsData[3]?.data.
                            TmCollateralType
                            .map((item) => (
                              <option key={item.ctid} value={item.ctid}>{item.ct}</option>
                            ))
                        }
                    </select>
                      </div>
                    </div>
                  )}
                </div>
                {!isEditButtonClicked && (
                <div className="col-md-12 form-add-button">
                  <button className="btn btn-secondary login-width" onClick={handleAddButtonClick} disabled={!isValid || !selectedDate} type="button">Add</button>
                </div>)}
                {isEditButtonClicked && (
                <div className="col-md-12 form-add-button">
                  <button className="btn btn-secondary login-width" onClick={handleUpdate} disabled={!isValid || !selectedDate} type="button">Update</button>
                    &nbsp;
                  <button className="btn btn-warning login-width mr-2" onClick={ () => setIsEditButtonClicked(!isEditButtonClicked)} type="button">Cancel</button>
                </div>)}
              </div>
            </form>
          </>
        )}
        
        {(!isEditButtonClicked && companyDetailsResult.length != 0) && (
        <div className="modal-content company-detail-modal">
          <h2> 
            <span className="company-title"><img src={buildingIcon}/></span> {tCompanyName}
          </h2>
            <div className="col-md-12 d-flex">
                <div className="col-md-9">
                  
                  <table className="companyDetailsTableWrapper">
                    <tbody>
                      <tr>
                        <td>Date:</td>
                        <td>{moment(companyDetailsResult?.incordate).format('DD-MM-YYYY')}</td>
                      </tr>
                      <tr>
                        <td>Company Type:</td>
                        <td>{companyDetailsResult?.ctype}</td>
                      </tr>
                      <tr>
                        <td>Nature of Business:</td>
                        <td>{companyDetailsResult?.busnature}</td>
                      </tr>
                      <tr>
                        <td>Industry Type:</td>
                        <td>{companyDetailsResult?.indtype}</td>
                      </tr>
                      <tr>
                        <td>Collateral:</td>
                        <td>{companyDetailsResult?.cltype}</td>
                      </tr>
                      <tr>
                        <td>Memorandum of Association (MOA):</td>
                        <td>{companyDetailsResult?.moa ? 'Completed' : 'Pending'}</td>
                      </tr>
                      <tr>
                        <td>Articles of Association (AOA):</td>
                        <td>{companyDetailsResult?.aoa ? 'Completed' : 'Pending'}</td>
                      </tr>
                      <tr>
                        <td>Certificate of Incorporation (CIN):</td>
                        <td>{companyDetailsResult?.cin ? 'Completed' : 'Pending'}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              

              <div className="col-md-3">
                <span className="icons" onClick={(prev) => {
                  setIsEditButtonClicked(true)
                  }}>
                  <img src={editIcon} />
                </span>
                <span className="icons" onClick={handleAddButtonDelete}>
                  <img src={deleteIcon} />
                </span>
              </div>
              
            </div>
        </div>
        )}
      </div>
    </>
  );
}

export default CompanyDetail;