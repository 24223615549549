import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

function EmploymentType() {
    const [selectedEmpType, setSelectedEmpType] = useState('');
    const [empTypes, setEmpTypes] = useState([]);
    const [loader , setLoader] = useState(false)
    const[activeIndex , setActiveIndex] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
       getEmploymentTypes()
    }, []);

  

    const getEmploymentTypes = async () => {
        setLoader(true)
        try {
            const response = await fetch('http://tb.binarycoders.in/api/TmEmpType');
            const data = await response.json();
            console.log("emptttttttttttt", data);
            setEmpTypes(data.TmEmpType);
        } catch (err) {
            console.log('err', err);
        } finally {
            setLoader(false); // This will run after the fetch completes
        }
    }

    const handleCheckChange = (empType , index) => {
        setSelectedEmpType(empType);
        setActiveIndex(index)
    };

    const saveEmpType = (e) => {
        e.preventDefault();
        navigate("/loan-amount", { state: { emptype: selectedEmpType } });
    };

    return (
        <>
            <Header />
            <div className='text-center mb-4 pb-2'>
                <h2 className='register-cont'>Choose Employment Type</h2>
            </div>
            {
                loader ? (
                    <div className='d-flex justify-content-center align-items-center'>
                        <h4 className='loaderHeading'>Loading...</h4>
                    </div>
                ):(
                  <>
                     <div className='basic-info-wrap'>
                {empTypes.map((emp, index) => (
                    <div key={index} className={`col-6 mb-5 ${activeIndex === index ? 'emptypeActive' : 'emptype' }`}>
                        <div className="left-text">
                            <h6>{emp.emptype}</h6>
                            <p>{emp.emptypedesc}</p>
                        </div>
                        <label className='checkbox-wrap right-checkbox'>
                            <input
                                className='checkBox'
                                type="checkbox"
                                checked={selectedEmpType === emp.emptypeid}
                                onChange={() => handleCheckChange(emp.emptypeid , index)}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                ))}
                    </div>
                    <div className='text-center mt-5'>
                        <button onClick={saveEmpType} className='btn btn-primary login-width' disabled={!selectedEmpType}>
                            Continue
                        </button>
                    </div>
                  </>
                ) 
            }
            
        </>
    );
}

export default EmploymentType;