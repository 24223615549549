import React, { useState, useRef, useCallback, useEffect } from "react";

export default function DocumentUploadCard({ onFilesSelected }) {  // Accept a prop
    
    const [dragActive, setDragActive] = useState(false);
    const [files, setFiles] = useState([]);
    const inputRef = useRef(null);
    
   
    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    }, []);

    const handleChange = useCallback((e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    }, []);

    const handleFiles = (selectedFiles) => {
        const validFiles = [];
        const allowedTypes = [
            "application/pdf",
            "application/doc",
            "image/jpeg",
            "image/png",
        ];
        const maxSize = 4 * 1024 * 1024; // 4 MB

        Array.from(selectedFiles).forEach((file) => {
            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                validFiles.push(file);
            } else {
                alert(`File ${file.name} is not a valid type or exceeds the size limit.`);
            }
        });

        if (validFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...validFiles]);

            // Notify the parent about the selected files
            if (onFilesSelected) {
                onFilesSelected(validFiles);
            }
        }
    };

    const onButtonClick = () => {
        if (files.length != 0) {
            inputRef.current.click();
        }
    };

    const removeFile = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div
            className={`file-upload-container`}
            onDragEnter={handleDrag}
        >
            <div
                className={`file-upload-box ${dragActive ? "drag-active" : ""}`}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    id="file-input"
                    className="file-input"
                    multiple
                    ref={inputRef}
                    onChange={handleChange}
                />
                <label htmlFor="file-input" className="file-label" onClick={onButtonClick}>
                    <i className="fas fa-link"></i> Select File
                </label>
                <p className="drag-drop-text">Drag and Drop File Here</p>
                    <div className="file-types">
                        <span className="file-type">PDF</span>
                        <span className="file-type">DOCX</span>
                        <span className="file-type">JPG</span>
                        <span className="file-size">&lt; 4 MB</span>
                    </div>
            </div>

            {files.length > 0 && (
                <ul className="file-list">
                    {files.length > 0 && (
                    <ul className="file-list">
                        {files.map((file, index) => (
                            <li key={index} className="file-item">
                                <span>{file.name}</span>
                                <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                                <button onClick={() => removeFile(index)} className="remove-button">
                                <i className="fas fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                </ul>
            )}
        </div>
    );
}
