export default function DirectorsBasicInfo({title, basicDetails, onEditClick}) {
    return (
        <>
        <h3>{title}</h3>
        <div className="company-detail-modal">  
            <div className="col-md-12 d-flex">
                <div className="col-md-3 d-flex align-items-center">
                <img src={basicDetails?.photo}
                        alt="" className="avatar-img rounded-circle" 
                        width="150" height="150"
                        />
                </div>
                <div className="col-md-8">
                    <h2> {basicDetails?.first_name} {basicDetails?.last_name} (Director)</h2>
                    <h4><strong>Mobile Number: </strong> <span>{basicDetails?.mobile_no}</span></h4>
                    <h4><strong>Email ID: </strong> <span>{basicDetails?.email}</span></h4>
                    <h4><strong>Date of Birth: </strong> <span>{basicDetails?.dob}</span></h4>
                    <h4><strong>Age: </strong> <span>32 Years 9 Months</span></h4>
                    <h4><strong>Marital Status: </strong> <span>{basicDetails?.marital_status}</span></h4>
                    <h4><strong>Aadhar Photo Match: </strong> <span className="text-primary">80%</span></h4>
                    <h4><strong>Pan KYC: </strong> <span className="text-primary">Verified</span></h4>
                    <h4><strong>Aadhar KYC: </strong> <span className="text-primary">Verified</span></h4>
                </div>
                <div className="col-md-1 d-flex align-items-center icon-container">
                    <span className="icons"><i className="fas fa-trash"></i></span>
                    <span className="icons" onClick={onEditClick}><i className="fas fa-edit"></i></span>
                </div>
            </div> 
        </div>
        </>
    );
}