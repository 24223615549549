import React, {useState, useCallback} from 'react';
import { useMultiStepForm } from '../../../hooks/useMultiStepForm.ts';
import UploadBalanceSheet from './UploadBalanceSheet.js';
import UploadBankDocument from './UploadBankDocument.js';
import GSTInfo from './GSTInfo.js';
import GSTValidation from './GSTValidation.js';
import BalanceSheetList from './BalanceSheetList.js';
import BankStatementList from './BankStatementList.js';

export default function BusinessFinancials() {
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    
    const setStepValid = useCallback((valid) => {
        setIsCurrentStepValid(valid);
      });

    const steps = [
        { component: <UploadBalanceSheet onNext={nextPage} setStepValid={setStepValid} />, name: "UploadBalanceSheet" },
        { component: <BalanceSheetList onNext={nextPage} />, name: "BalanceSheetList" },
        { component: <UploadBankDocument onNext={nextPage} setStepValid={setStepValid} />, name: "UploadBankDocument" },
        { component: <BankStatementList onNext={nextPage} />, name: "BankStatement" },
        { component: <GSTInfo />, name: "GSTInfo" },
        { component: <GSTValidation />, name: "GSTValidation" }
    ];


    const { step, isFirstStep, isLastStep, back, next, currentStepIndex } = useMultiStepForm(
        steps.map(s => s.component)
    );

    // Get the current step name for activating icons and links
    const currentStepName = steps[currentStepIndex]?.name;

    function nextPage() {
        if (!isLastStep) return next();
        alert("Steps completed!");
    }

    return (
        <div className="subcontent-wrapper">
            <div className="title">
                <h3>Business Financials <span className="inprogress-text text-primary">In Progress</span></h3>
            </div>
            <div className="inner-dashboard">
                <div className="left-navtabs">
                    <ul className="nav flex-column">
                        <li className={`nav-item ${currentStepName === "UploadBalanceSheet" ? "active" : ""}`}>
                            <i className={`far fa-clock ${currentStepName === "UploadBalanceSheet" ? "active-clock" : ""}`}></i>
                            <a className="nav-link" href="#" style={{ color: currentStepName === "UploadBalanceSheet" ? "#3276E8" : "#5A5A5A" }}>
                                Balance Sheet
                            </a>
                        </li>
                        <li className={`nav-item ${currentStepName === "UploadBankDocument" ? "active" : ""}`}>
                            <i className={`far fa-clock ${currentStepName === "UploadBankDocument" ? "active-clock" : ""}`}></i>
                            <a className="nav-link" href="#" style={{ color: currentStepName === "UploadBankDocument" ? "#3276E8" : "#5A5A5A" }}>
                                Bank Statement
                            </a>
                        </li>
                        <li className={`nav-item ${currentStepName === "GSTValidation" ? "active" : ""}`}>
                            <i className={`far fa-clock ${currentStepName === "GSTValidation" ? "active-clock" : ""}`}></i>
                            <a className="nav-link" href="#" style={{ color: currentStepName === "GSTValidation" ? "#3276E8" : "#5A5A5A" }}>
                                GST Validation
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="right-form-content">
                    {step}
                </div>
            </div>
            <div className="nextpage-section">
                {!isFirstStep && (
                    <input
                        type="submit"
                        onClick={back}
                        className="btn btn-outline-secondary btn"
                        value="PREVIOUS"
                    />
                )}
                <input
                    type="submit"
                    onClick={() => nextPage()}
                    className="btn btn-secondary btn"
                    disabled={!isCurrentStepValid}
                    value="NEXT"
                />
            </div>
        </div>
    );
}
