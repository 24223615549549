
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Edit from '../../../assets/images/edit.png'
import Delete from '../../../assets/images/delete.png'

function BankAccountList({newAccountBtn, isEditMode}) {
    const [accountList, setAccountList] = useState([]);

    const getBankAccountList = () => {
        const company_id = localStorage.getItem('company_id');
        axios.get('http://tb.binarycoders.in/api/businessAccount/show', {
            params: {
                cid: company_id,
            }}).then((response) => {
            setAccountList(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
        
    }

    const  maskNumber = (number) => {
        let str = number.toString(); // Convert the number to a string
        let lastFour = str.slice(-4); 
        let firstSeg = number.length - lastFour.length// Get the last 4 digits
        let masked = 'X'.repeat(firstSeg) + lastFour; // Concatenate 'XXXXX' with the last 4 digits
        return masked;
      }
      

    useEffect(() => {
        getBankAccountList();
    }, []);

    return (
        <>
            <div className="d-flex">
                <h3>Bank Account</h3>
                <h5 className="ms-auto" onClick={newAccountBtn}><i className="fas fa-plus text-primary"></i> Add New Account</h5>
            </div>
            <ul className="bank-list">

            {accountList.map((account) => {
                return (
                    <li key={account.id}>
                        <span className="icon"><i className="fas fa-university"></i></span>
                        <div className="bank-info">
                            <h4>{account.bname} {account.accpurpose}</h4>
                            <p>Account: {maskNumber(account.accno)} IFSC Code: {maskNumber(account.ifsccode)}</p>
                        </div>
                        <div className="edit-del-icon">
                            <img src={Edit} height={19} width={19} onClick={() => isEditMode(account)}></img>
                            <img src={Delete} height={20} width={14} style={{color:'#98A9BC'}}></img>
                        </div>
                        <div className='verifiedContainer'>
                            <i className="fa-regular fa-circle-check" style={{color:'#177AF4',paddingRight:'0.5rem'}}></i>
                            <span className='verified-text'>Verified</span>
                        </div>
                    </li>)  
            })}
              
                {/* <li className="mt-5">
                    <span className="icon"><i className="fas fa-university"></i></span>
                    <div className="bank-info">
                        <h4>HDFC Bank (Repayment Account)</h4>
                        <p>Account: xxxxxx0000123 IFSC Code: xxxxxxx000</p>
                    </div>
                    <div className="edit-del-icon">
                        <i className="fas fa-edit"></i>
                        <i className="fas fa-trash"></i>
                    </div>
                    <span className="verified-text"><i className="far fa-clock"></i> Verified</span>
                </li> */}
            </ul>
        </>
    )
}


export default BankAccountList