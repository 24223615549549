import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import calenderIcon from '../../../assets/images/icons/calendar.svg';
import arrowDownIcon from '../../../assets/images/icons/arrow-down.svg';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';
import BalanceSheetList from './BalanceSheetList';

export default function UploadBalanceSheet({  onNext, setStepValid }) {
    const [financialYear, setFinancialYear] = useState('');
    const [turnover, setTurnover] = useState('');
    const [uploadedData, setUploadedData] = useState([]);
    const [uploadedYears, setUploadedYears] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState(null);
    const [years, setYears] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdded, setIsAdded] = useState(false);
    
    const formRef = useRef(null);



    const storeAPI = 'http://tb.binarycoders.in/api/balsheet';
    const updateAPI = 'http://tb.binarycoders.in/api/balsheet/update?id=';
    const getAPI = 'http://tb.binarycoders.in/api/balsheet/show?cid=';

    const financialYearsOptions = () => {
        // Calculate current year and last two years dynamically
        let currentYear = new Date().getFullYear();
        setYears([currentYear, currentYear - 1, currentYear - 2]);
    };

    const handleFilesSelected = (files) => {
        setUploadedFiles(files); // Set selected files
    };


    // Function to handle form submission
    const handleAdd = async () => {
        if (!financialYear || !turnover || !uploadedFiles) return;
        let userId = localStorage.getItem('user_id'); 
        let companyId = localStorage.getItem('company_id'); 

        try {
            // Prepare form data for uploading
            const formData = new FormData();
            formData.append('uid', userId); // Assuming user ID is 1, change accordingly
            formData.append('cid', companyId); // Assuming company ID is 123, change accordingly
            
    
            // Append each uploaded file
            if (uploadedFiles.length > 0) {
                formData.append('findoc', uploadedFiles[0]);
            }

            formData.append('finyear', financialYear);
            formData.append('bustrnover', turnover);

            // Make API call with axios to upload file and data
            const response = await axios.post(storeAPI, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // // Add uploaded data to the list
            // setUploadedData(prevData => [...prevData, { financialYear, turnover }]);
            // setUploadedYears(prevYears => [...prevYears, financialYear]);

            getBalanceSheet();

            // Reset form fields
            setFinancialYear('');
            setTurnover('');
            setUploadedFiles(null);
            formRef.current.reset();
            setIsAdded(true);
            console.log('Step Valid:', uploadedData.length);
            if (uploadedData.length === 2) {
                onNext();
            }
          
        } catch (error) {
            console.error('Error uploading document', error);
        }
    };

    // Disable "Add" button if form is incomplete
    const isFormComplete = financialYear && turnover && uploadedFiles;

    
    // Handle editing (populate fields with existing data)
    const handleEdit = (id) => {
        
        // Get the data of the selected balance sheet
        const selectedData = uploadedData.find(data => data.id === id);
        
        // Set the form fields with the selected data for editing
        setFinancialYear(selectedData.finyear);
        setTurnover(selectedData.bustrnover);
        
        // Store the id to know which item is being edited
        setIsEditing(true);
        setEditIndex(id);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!financialYear || !turnover || !uploadedFiles) return;
        let userId = localStorage.getItem('user_id');
        let companyId = localStorage.getItem('company_id');
        let id = editIndex;

        try {
            // Prepare form data for uploading
            const formData = new FormData();
            formData.append('uid', userId); // Assuming user ID is 1, change accordingly
            formData.append('cid', companyId); // Assuming company ID is 123, change accordingly
            
    
            // Append each uploaded file
            if (uploadedFiles.length > 0) {
                formData.append('findoc', uploadedFiles[0]);
            }

            formData.append('finyear', financialYear);
            formData.append('bustrnover', turnover);

            // Make API call with axios to upload file and data
            const response = await axios.post(updateAPI + id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            response.status === 200 && setIsEditing(false);

            getBalanceSheet();

            // Reset form fields
            setFinancialYear('');
            setTurnover('');
            setUploadedFiles(null);
            formRef.current.reset();
            onNext();
        } catch (error) {
            console.error('Error uploading document', error);
        }
    }


    // Handle deleting an uploaded entry
    const handleDelete = (index) => {
        const updatedData = [...uploadedData];
        const deletedYear = updatedData[index].finyear;
        updatedData.splice(index, 1);
        setUploadedData(updatedData);

        // Also remove the financial year from the uploaded years list
        setUploadedYears(uploadedYears.filter(year => year !== deletedYear));
    };

    const filterUploadedYears = () => {
        let uploadedYears = uploadedData.map(data => Number(data.finyear));
        let filteredYears = years.filter(year => uploadedYears.includes(year));
        setUploadedYears(filteredYears);

        // setUploadedYears(filteredYears);

        // const fyear = uploadedData.filter(item => {
        //     console.log('Item:', item);
        //     return item.finyear !== 2023
        // });
        // console.log('Filtered Year:', fyear);

        
        
    }

    

     // Function to fetch balance sheet data
     const getBalanceSheet = () => {
        let companyId = localStorage.getItem('company_id')
        axios.get(getAPI + companyId)
            .then(response => {
                const data = response.data.data; // Assuming data is an array of balance sheets
                setUploadedData(data); 
                // filterUploadedYears();
                
                if (data.length === 3) {
                    setStepValid(true);
                }
                
            })
            .catch(error => {
                console.error('Error fetching balance sheets:', error);
            });
            console.log('get Uploaded Data:', uploadedData);
    };

    const checkIfYearUploaded = (year) => {
        return uploadedData.some(data => Number(data.finyear) === year);
    };

    
    useEffect(() => {
        getBalanceSheet();
        financialYearsOptions();
        // filterUploadedYears();
        console.log('Uploaded data:', uploadedData);
    }, []);

    useEffect(() => {
        getBalanceSheet();
    }, []);

    

    return (
        <div className="documents-section">
            <h3>Upload Balance Sheet</h3>
            <ul className="nav nav-tabs">
            {years.map((year, index) => (
                    <li key={index} className="nav-item d-flex align-items-center">
                        <a className="nav-link" data-bs-toggle="tab" href={`#year${index}`}>
                            {year === years[0] ? "Current Year" : year === years[1] ? "Last Year" : "Year Before"}
                        </a>
                        <i className={checkIfYearUploaded(year) ? "fa-regular fa-circle-check" : "far fa-clock"}></i>
                    </li>
                ))}
            </ul>
            {(uploadedData.length < 3 || isEditing) &&
        <form ref={formRef}>
                <div className="col-md-12 tab-content">
                    <div id="currentYear" className="tab-pane active">
                        <div className="d-flex dashboard-form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <span className="form-control-icon">
                                        <img src={arrowDownIcon} alt="Arrow Down" />
                                    </span>
                                    <select
                                        className="form-select form-control"
                                        value={financialYear}
                                        onChange={(e) => setFinancialYear(e.target.value)}
                                    >
                                        <option>Select The Financial Year</option>
                                        {years.map(year => (
                                            !uploadedYears.includes(year) && (
                                                <option key={year} value={year}>{year}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Business Turnover (In Lacs)"
                                        value={turnover}
                                        onChange={(e) => setTurnover(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <DocumentUploadCard onFilesSelected={handleFilesSelected} />
                        </div>
                    </div>
                    <div id="lastYear" className="tab-pane"></div>
                    <div className="col-md-12 form-add-button">
                    {isEditing ? (
                        <>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleUpdate}
                            disabled={!isFormComplete}
                        >Update</button>
                        &nbsp;
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setIsEditing(false)}
                        >
                            Cancel
                        </button>
                        </>
                    ) : (<button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAdd}
                            disabled={!isFormComplete}
                        >
                            Add
                        </button>)}
                        
                        
                    </div>
                </div>
            </form>
        }
                                        
            <div className='mt-5'>
            <BalanceSheetList
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                isHeading={true}
                isAdded={isAdded}
            />
            </div>
        </div>
    );
}
