import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function GrossAnnualTurnover() {
  const [selectedTurnoverId, setSelectedTurnoverId] = useState('');
  const [annualTurnover, setAnnualTurnover] = useState([]);
  const [isContinueEnableButton, setIsContinueEnableButton] = useState(false);
  const [loader , setLoader] = useState(false)
  
  const location = useLocation();
  const Loanamount = location.state?.locan_amount;
  const Emp_type=location.state?.emp_type;
  const navigate=useNavigate();
  const userUpdate= async(e)=>{
    e.preventDefault();
    const uid = localStorage.getItem("user_id");
    const url='http://tb.binarycoders.in/api/updateDetails'
    try{
    const response= await fetch(url,{
      method:'POST',
      body:JSON.stringify({
        userId:uid,
        emptype:Emp_type,
        grossturnover:selectedTurnoverId,
        loanamount:Loanamount,
        status:3,
      }),
      headers:{
        "Content-Type": "application/json",
      }
    })
    const data=response.json();
    if(response.ok){
      console.log("success",data);
      navigate('/dashboard/onboarding/business-kyc');
    }else{
      console.log("An Error Occur");
    }
  }catch(error){
    console.log("an error occur:", error);
  }
  }
  useEffect(() => {
     getTurnOver()
  }, []);

  const getTurnOver = async () => {
    setLoader(true)
     try{
      fetch('http://tb.binarycoders.in/api/TmTurnover')
      const response = await fetch('http://tb.binarycoders.in/api/TmTurnover');
            const data = await response.json();
            console.log("emptttttttttttt", data);
            setAnnualTurnover(data.TmTurnover)
      
     }catch(error){
       console.log("Error fetching annual turnover:",error)
     }finally{
       setLoader(false)
     }
  }

  const handleCheckChange = (id) => {
    setSelectedTurnoverId(id);
    setIsContinueEnableButton(true);  
  };

  return (
    <div className="annual-turnover">
      <Header />
      <div className="text-center mb-4 mt-5 pb-2">
        <h2 className="register-cont">Gross Annual Turnover</h2>
      </div>
      {
         loader ? (
          <div className='d-flex justify-content-center align-items-center'>
              <h4 className='loaderHeading'>Loading...</h4>
          </div>
         ):(
          <>
            <div className='basic-info-wrap'>
            {annualTurnover.map((item, index) => (
              <div key={index} className="amount-range col-6 mb-4" style={{ borderColor: selectedTurnoverId === item.toid ? "#3276E8" : "#BBBBBB" }}>
                <div className="left-text">
                  <p>{item.todesc}</p>
                </div>
                <label className='checkbox-wrap right-checkbox'>
                  <input
                    className="checkBox"
                    type="checkbox"
                    checked={selectedTurnoverId === item.toid}
                    onChange={() => handleCheckChange(item.toid)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
            </div>
            <div className="text-center mt-5">
              <Link
                onClick={userUpdate}
                className={`btn btn-primary login-width ${isContinueEnableButton ? "" : "disabled"}`}
                disabled={!isContinueEnableButton}
              >
                Continue
              </Link>
            </div>
          </>
         )
      }
     
    </div>
  );
}

export default GrossAnnualTurnover;