import React, {useState, useCallback} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { useMultiStepForm } from "../../../hooks/useMultiStepForm.ts";
import CompanyDetail from "./CompanyDetail";
import BusinessAddress from "./BusinessAddress.js";
import Documents from "./Documents.js";
import BusinessVerfication from "./BusinessVerfication.js";
import BankAccount from "./BankAccount.js";
import BankAccountList from "./BankAccountList.js";


export default function BusinessKYC() {
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [ stepsCompleted, setStepsCompleted] = useState([]);
  const navigate = useNavigate();

  const setStepValid = useCallback((valid) => {
    setIsCurrentStepValid(valid);
  });
  
  const steps = [
    { component: <CompanyDetail onNext={nextPage} setStepValid={setStepValid}/>, name: "CompanyDetail" },
    { component: <BusinessAddress onNext={nextPage} setStepValid={setStepValid} />, name: "BusinessAddress" },
    { component: <Documents onNext={nextPage} setStepValid={setStepValid}/>, name: "Documents" },
    { component: <BusinessVerfication onNext={nextPage} setStepValid={setStepValid}/>, name: "BusinessVerfication" },
    { component: <BankAccount onNext={nextPage} setStepValid={setStepValid}/>, name: "BankAccount" },
    { component: <BankAccountList onNext={nextPage} setStepValid={setStepValid}/>, name: "BankAccountList" },
  ];

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } = useMultiStepForm(
    steps.map((s) => s.component)
  );

  function trigerToaster(message){
    toast(message)
  }

  const currentStepName = steps.find((s) => s.component === step)?.name;

  function nextPage() {
    if(currentStepIndex === 2){
      setStepsCompleted((prev)=>{
        return [...prev, 'BasicInfo']
      })
    } else if(currentStepIndex === 3){
      setStepsCompleted((prev)=>{
        return [...prev, 'BusinessVerfication']
      })
    } else if(currentStepIndex === 4){
      setStepsCompleted((prev)=>{
        return [...prev, 'BankAccount']
      })
    }

    if (!isLastStep) return next();
      navigate("/dashboard/onboarding/management-kyc");
  }
  

  return (
    <div className="subcontent-wrapper">
    <ToastContainer/>
      <div className="title">
        <h3>
          Business KYC{" "}
          <span className="inprogress-text text-primary">In Progress</span>
        </h3>
      </div>
      <div className="inner-dashboard">
        <div className="left-navtabs">
          <ul className="nav flex-column">
            <li className={`nav-item ${["CompanyDetail", "BusinessAddress", "Documents"].includes(currentStepName) ? "active" : ""}`}>
              <i className={`far fa-clock`} style={{color:["CompanyDetail", "BusinessAddress", "Documents"].includes(currentStepName) ? "#C87A7A" : stepsCompleted.includes('BasicInfo') ? "#177AF4" : ""}}></i>
              <a
                className="nav-link"
                href="#"
                style={{
                  color: ["CompanyDetail", "BusinessAddress", "Documents"].includes(currentStepName)
                    ? "#3276E8"
                    : "#5A5A5A",
                }}
              >
                Basic Info
              </a>
            </li>
            <li className={`nav-item ${currentStepName === "BusinessVerfication" ? "active" : ""}`}>
              <i className={`far fa-clock`} style={{color: currentStepName === "BusinessVerfication" ? "#C87A7A" : stepsCompleted.includes('BusinessVerfication') ? "#177AF4" : ""}}></i>
              <a
                className="nav-link"
                href="#"
                style={{
                  color: currentStepName === "BusinessVerfication" ? "#3276E8" : "#5A5A5A",
                }}
              >
                Verification
              </a>
            </li>
            <li className={`nav-item ${currentStepName === "BankAccount" ? "active" : ""}`}>
              <i className={`far fa-clock`} style={{color: currentStepName === "BankAccount" ? "#C87A7A" : stepsCompleted.includes('BankAccount') ? "#177AF4" : ""}}></i>
              <a
                className="nav-link"
                href="#"
                style={{
                  color: currentStepName === "BankAccount" ? "#3276E8" : "#5A5A5A",
                }}
              >
                Bank Account
              </a>
            </li>
          </ul>
        </div>
        <div className="right-form-content">{step}</div>
      </div>
      <div className="nextpage-section">
      { !["CompanyDetail", "BusinessAddress", "Documents"].includes(currentStepName) && (
          <input
            type="button"
            onClick={()=>{
              back();
              setStepValid(true);
            }}
            className="btn btn-secondary nextbtn"
            value="PREVIOUS"
          />
        )}
        <input
          type="submit"
          onClick={() => nextPage()}
          className="btn btn-secondary btn nextbtn"
          disabled={!isCurrentStepValid}
          value="NEXT"
        />
      </div>
    </div>
  );
}
