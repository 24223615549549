import { useEffect, useState } from 'react';
import axios from "axios";
import PersonBasicDetails from '../../SharedComponents/PersonBasicDetails';
import DocumentUploadCard from '../../SharedComponents/DocumentUploadCard';
import PanVerifiedCard from '../../SharedComponents/PanVerifiedCard';
import AadharVerifiedCard from '../../SharedComponents/AadharVerified';
import DirectorBasicDetails from "../../SharedComponents/DirectorBasicInfo";


export default function DirectorsKYC({onNext, setStepValid}) {
const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
    
    const [isNext, setIsNext] = useState(false);

    const [basicDetails, setBasicDetails] = useState([]);
    const [kycDetails, setKycDetails] = useState([]);
    const [panVerified,  setPanVerified] = useState(false);
    const [aadhardVerified,  setAadharVerified] = useState(false);
    // var apiUrl = 'http://localhost/tradebee/api';
    var apiUrl = 'http://tb.binarycoders.in/api';

    const getBasicDetailsApi = apiUrl + '/managementKyc/directorBasicInfo/user/';    

    const getBasicDetails = async () => {
        let userId = localStorage.getItem('user_id');
        axios.get(getBasicDetailsApi + userId)
        .then(response => {
            if (response.status === 200 && response.data.data) {
                const data = response.data.data;
                setBasicDetails(data);
                setIsLoading(false);
                setStepValid(true);               
            } else {
                setIsLoading(false);
            }
        });
    };

    
    useEffect(() => {
        getBasicDetails();
        console.log('panVerified',kycDetails);
      }, []);

    return (
        <>
            <h3 className="mt-5">Directors KYC</h3>
            <div className="KYC-completion">
                <li>
                    <i className="fas fa-file-alt"></i>
                    <h3><i className="fas fa-plus text-primary"></i> Complete the KYC</h3>
                </li>
                <li>
                    <i className="fas fa-file-alt"></i>
                    <h3><i className="fas fa-plus text-primary"></i> Verify CIBIL Report</h3>
                </li>
            </div>
            <div className="d-flex dashboard-form-row verification-list">
                <div className="col-md-6 border col">
                    <span className="verified-text"><i className="far fa-clock"></i> Verified</span>
                    <div className="info">
                        <h3>Aadhar</h3>
                        <h5>xxxx xxxx xxxx xxxx</h5>
                        <p>ID Proof</p>
                    </div>
                    <div className="col-md-3 icons-wrap">
                        <span className="icons"><i className="fas fa-edit"></i></span>
                        <span className="icons"><i className="fas fa-trash"></i></span>
                    </div>
                </div>
                <div className="col-md-6 border col">
                    <span className="verified-text"><i className="far fa-clock"></i> Verified</span>
                    <div className="info">
                        <h3>PAN</h3>
                        <h5>xxxxxxxxx0256</h5>
                        <p>Age Proof</p>
                    </div>
                    <div className="col-md-3 icons-wrap">
                        <span className="icons"><i className="fas fa-edit"></i></span>
                        <span className="icons"><i className="fas fa-trash"></i></span>
                    </div>
                </div>
            </div>
            <div className="d-flex dashboard-form-row verification-list">
                <div className="col-md-6 border col">
                    <span className="verified-text"><i className="far fa-clock"></i> Verified</span>
                    <div className="info">
                        <h3>Company CIBIL</h3>
                        <h5>xxxxxxxxx0256</h5>
                        <a href="#" className="mt-4 d-inline-block">Detail Report</a>
                    </div>
                    <div className="col-md-3 icons-wrap">
                        <span className="icons"><i className="fas fa-edit"></i></span>
                        <span className="icons"><i className="fas fa-trash"></i></span>
                    </div>
                </div>
            </div>
            { basicDetails.length != 0 && isEditButtonClicked == false ? (<PersonBasicDetails title="Director Basic Info" basicDetails={basicDetails} />) : ''}
        </>
    )
}